import React from 'react';
import { ArrowLeft, Shield } from 'lucide-react';
import { Button } from '../components/ui/Button';

const PrivacySection = ({ title, children }) => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold text-amber-800 dark:text-amber-200 mb-4">{title}</h2>
    <div className="space-y-4 text-amber-600 dark:text-amber-400">
      {children}
    </div>
  </div>
);

const PrivacyView = ({ setView }) => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-8">
        <Button
          onClick={() => {setView('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
          variant="ghost"
          className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
        >
          <ArrowLeft className="w-5 h-5 text-amber-600 dark:text-amber-400" />
        </Button>
        <div>
          <h1 className="text-2xl font-bold text-amber-800 dark:text-amber-200 flex items-center gap-2">
            <Shield className="w-7 h-7" />
            Privacy Policy
          </h1>
          <p className="text-amber-600 dark:text-amber-400 mt-1">
            Last updated: December 9, 2024
          </p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8 space-y-8">
        <PrivacySection title="1. Information We Collect">
          <p>We collect the following types of information:</p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Account information (name, email, password)</li>
            <li>Study content and flashcards you create</li>
            <li>Learning progress and usage statistics</li>
            <li>Device and browser information</li>
          </ul>
        </PrivacySection>

        <PrivacySection title="2. How We Use Your Information">
          <p>Your information is used to:</p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Provide and improve our learning services</li>
            <li>Personalize your learning experience</li>
            <li>Track and analyze study progress</li>
            <li>Send important updates and notifications</li>
            <li>Maintain account security</li>
          </ul>
        </PrivacySection>

        <PrivacySection title="3. Data Storage and Security">
          <p>
            We implement industry-standard security measures to protect your data:
          </p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Encryption of data in transit and at rest</li>
            <li>Regular security audits and updates</li>
            <li>Secure access controls and authentication</li>
            <li>Regular backups and disaster recovery procedures</li>
          </ul>
        </PrivacySection>

        <PrivacySection title="4. AI and Data Processing">
          <p>
            Our AI features process your uploaded content to generate flashcards. This data is:
          </p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Used only for generating study materials</li>
            <li>Not shared with third parties</li>
            <li>Processed in accordance with data protection laws</li>
            <li>Deleted upon request</li>
          </ul>
        </PrivacySection>

        <PrivacySection title="5. Data Sharing">
          <p>
            We do not sell your personal information. Data sharing is limited to:
          </p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Service providers necessary for platform operation</li>
            <li>Legal requirements and law enforcement requests</li>
            <li>Protection of rights and safety</li>
          </ul>
        </PrivacySection>

        <PrivacySection title="6. Your Rights">
          <p>You have the right to:</p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Access your personal data</li>
            <li>Request data correction or deletion</li>
            <li>Export your data</li>
            <li>Opt out of non-essential data collection</li>
            <li>Withdraw consent for data processing</li>
          </ul>
        </PrivacySection>

        <PrivacySection title="7. Cookies and Tracking">
          <p>
            We use cookies and similar technologies to improve user experience and analyze platform usage. You can control cookie preferences through your browser settings.
          </p>
        </PrivacySection>

        <PrivacySection title="8. Children's Privacy">
          <p>
            RecallPeak is not intended for children under 13. We do not knowingly collect personal information from children under 13.
          </p>
        </PrivacySection>

        <PrivacySection title="9. Changes to Privacy Policy">
          <p>
            We may update this privacy policy periodically. Users will be notified of significant changes.
          </p>
        </PrivacySection>

        <PrivacySection title="10. Contact Information">
          <p>
            For privacy-related inquiries, contact us at:
          </p>
          <p className="mt-2">
            privacy@recallpeak.com
          </p>
        </PrivacySection>
      </div>
    </div>
  );
};

export default PrivacyView;