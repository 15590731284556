import React, { useState, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Pencil, 
  Trash2, 
  AlertCircle, 
  FolderOpen,
  Plus,
  ArrowLeft,
  Info,
  Search,
  FileText,
  Archive,
  ChevronRight,
  AlertTriangle,
  Clock,
  Loader
} from 'lucide-react';
import { Button } from '../components/ui/Button';
import DeleteConfirmationDialog from '../components/DeleteConfirmation';
import { API_URL } from '../config/api';

const CategoryCard = ({
  category,
  cardCount,
  isEditing,
  onEdit,
  onDelete,
  onSave,
  onCancel,
  newName,
  setNewName,
  lastUsed,
  dueCards,
  setCategoryToDelete
}) => {
  const isGeneral = category === 'General';
  
  const getLastUsedText = () => {
    if (!lastUsed) return 'Never used';
    const days = Math.floor((new Date() - new Date(lastUsed)) / (1000 * 60 * 60 * 24));
    if (days === 0) return 'Used today';
    if (days === 1) return 'Used yesterday';
    return `Last used ${days} days ago`;
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="group bg-white dark:bg-gray-800 rounded-xl border border-amber-100 dark:border-gray-700 
                 hover:border-amber-200 dark:hover:border-amber-800 
                 hover:shadow-md transition-all duration-200"
    >
      {isEditing ? (
        <form onSubmit={onSave} className="p-4">
          <div className="flex items-center gap-3 mb-3">
            <FolderOpen className="w-5 h-5 text-amber-500 dark:text-amber-400" />
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              className="flex-1 px-3 py-2 border border-amber-200 dark:border-gray-600 
                       bg-white dark:bg-gray-700 text-amber-800 dark:text-amber-100
                       rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500
                       placeholder:text-amber-300 dark:placeholder:text-amber-500"
              placeholder="Enter category name..."
              autoFocus
            />
          </div>
          <div className="flex items-center gap-2 justify-end">
            <Button 
              type="button" 
              onClick={onCancel}
              className="px-4 py-2 text-amber-600 dark:text-amber-400 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              className="px-4 py-2 bg-amber-500 dark:bg-amber-600 text-white rounded-lg
                       hover:bg-amber-600 dark:hover:bg-amber-700 transition-colors"
            >
              Save Changes
            </Button>
          </div>
        </form>
      ) : (
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 dark:bg-amber-900/30 rounded-lg">
                <FolderOpen className="w-5 h-5 text-amber-500 dark:text-amber-400" />
              </div>
              <div>
                <h3 className="font-medium text-amber-800 dark:text-amber-200 flex items-center gap-2">
                  {category}
                  {isGeneral && (
                    <span className="text-xs px-2 py-0.5 bg-amber-100 dark:bg-amber-900/50 rounded-full">
                      Default
                    </span>
                  )}
                </h3>
                <div className="flex items-center gap-3 mt-1">
                  <span className="text-sm text-amber-600 dark:text-amber-400 flex items-center gap-1">
                    <FileText className="w-3 h-3" />
                    {cardCount} cards
                  </span>
                  {dueCards > 0 && (
                    <span className="text-sm text-amber-600 dark:text-amber-400 flex items-center gap-1">
                      <Clock className="w-3 h-3" />
                      {dueCards} due
                    </span>
                  )}
                </div>
              </div>
            </div>
            
            <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
              <Button
                onClick={onEdit}
                disabled={isGeneral}
                className="p-2 text-amber-600 dark:text-amber-400 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg
                         disabled:opacity-50 disabled:cursor-not-allowed
                         relative group/tooltip"
                /* title={isGeneral ? "Can't edit default category" : "Edit category"} */
              >
                <Pencil className="w-4 h-4" />
                <span className="absolute -bottom-8 right-0 bg-amber-900 dark:bg-amber-800 text-white text-xs
                               px-2 py-1 rounded opacity-0 group-hover/tooltip:opacity-100 
                               transition-opacity whitespace-nowrap">
                  {isGeneral ? "Can't edit default" : "Edit category"}
                </span>
              </Button>
              <Button
                onClick={() => setCategoryToDelete(category)}
                disabled={isGeneral}
                className="p-2 text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg
                         disabled:opacity-50 disabled:cursor-not-allowed
                         relative group/tooltip"
                /* title={isGeneral ? "Can't delete default category" : "Delete category"} */
              >
                <Trash2 className="w-4 h-4" />
                <span className="absolute -bottom-8 right-0 bg-red-600 dark:bg-red-500 text-white text-xs
                               px-2 py-1 rounded opacity-0 group-hover/tooltip:opacity-100 
                               transition-opacity whitespace-nowrap">
                  {isGeneral ? "Can't delete default" : "Delete category"}
                </span>
              </Button>
            </div>
          </div>
          
          <div className="mt-3 text-xs text-amber-500 dark:text-amber-400">
            {getLastUsedText()}
          </div>
        </div>
      )}
    </motion.div>
  );
};

const CategoryManagerView = ({
  //categories = ['General'],
  cards = [],
  onEditCategory,
  onDeleteCategory,
  setView
}) => {
  const [categories, setCategories] = useState(['General']);
  const [editingCategory, setEditingCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  // Add this useEffect for fetching categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/categories`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }

        const data = await response.json();
        
        // Ensure General category is always present
        const uniqueCategories = new Set(['General']);
        data.forEach(category => {
          if (typeof category === 'object') {
            uniqueCategories.add(category.name);
          } else {
            uniqueCategories.add(category);
          }
        });

        setCategories(Array.from(uniqueCategories));
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Failed to load categories');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []); // Empty dependency array means this runs once when component mounts

  const categoryStats = useMemo(() => {
    return categories.map(category => {
      const categoryCards = cards.filter(card => card.category === category);
      const lastUsedCard = categoryCards.reduce((latest, card) => {
        if (!latest.last_review_date) return card;
        return new Date(card.last_review_date) > new Date(latest.last_review_date) ? card : latest;
      }, { last_review_date: null });
      
      return {
        name: category,
        cardCount: categoryCards.length,
        lastUsed: lastUsedCard.last_review_date,
        dueCards: categoryCards.filter(card => {
          const dueDate = new Date(card.next_review_date);
          return dueDate <= new Date();
        }).length
      };
    });
  }, [categories, cards]);

  const filteredCategories = categoryStats.filter(cat => 
    cat.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = async () => {
    try {
      if (categoryToDelete === 'General') {
        setError('Cannot delete General category');
        return;
      }
      
      setDeleteLoading(true);
      await onDeleteCategory(categoryToDelete);
      setCategoryToDelete(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!newCategoryName.trim()) {
      setError('Category name cannot be empty');
      return;
    }
    
    if (categories.includes(newCategoryName) && newCategoryName !== editingCategory) {
      setError('Category already exists');
      return;
    }

    try {
      await onEditCategory(editingCategory, newCategoryName);
      setEditingCategory(null);
      setNewCategoryName('');
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClose = () => {
    setView('dashboard');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Add loading state */}
      {loading ? (
        <div className="flex items-center justify-center py-12">
          <Loader className="w-8 h-8 animate-spin text-amber-500" />
        </div>
      ) : (
        <>
        {/* Back to Dashboard Button - Visible on desktop only */}
      <div className="hidden md:block">
        <Button
          onClick={handleClose}
          className="px-4 py-2 bg-white dark:bg-gray-800 text-amber-600 dark:text-amber-400 
                   hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg border border-amber-100 
                   dark:border-amber-900/50 flex items-center gap-2 transition-colors"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Dashboard
        </Button>
      </div>
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Button
            onClick={() => {setView('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
            variant="ghost"
            className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg transition-colors"
          >
            <ArrowLeft className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          </Button>
          <div>
            <h1 className="text-2xl font-bold text-amber-800 dark:text-amber-200">Categories</h1>
            <p className="text-amber-600 dark:text-amber-400 mt-1">
              {categories.length} categories • {cards.length} total cards
            </p>
          </div>
        </div>
        
        <Button
          onClick={() => {setView('add'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
          className="px-4 py-2 bg-gradient-to-r from-amber-500 to-amber-600 
                   dark:from-amber-600 dark:to-amber-700
                   text-white rounded-lg shadow-sm hover:shadow-md 
                   transition-all duration-200 flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          New Category
        </Button>
      </div>

      {/* Search & Info */}
      <div className="flex flex-col md:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-amber-500 dark:text-amber-400 w-5 h-5" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search categories..."
            className="w-full pl-10 pr-4 py-2 border border-amber-200 dark:border-gray-600 
                     bg-white dark:bg-gray-700 text-amber-800 dark:text-amber-100
                     rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500
                     placeholder:text-amber-300 dark:placeholder:text-amber-500"
          />
        </div>
        
        {error && (
          <div className="flex items-center gap-2 px-4 py-2 bg-red-50 dark:bg-red-900/20 border 
                         border-red-200 dark:border-red-800 rounded-lg text-red-600 dark:text-red-400 animate-shake">
            <AlertCircle className="w-4 h-4 shrink-0" />
            <span className="text-sm">{error}</span>
          </div>
        )}
      </div>

      {/* Categories Grid */}
      <div className="grid gap-4">
        <AnimatePresence>
          {filteredCategories.map(({ name, cardCount, lastUsed, dueCards }) => (
            <CategoryCard
              key={name}
              category={name}
              cardCount={cardCount}
              lastUsed={lastUsed}
              dueCards={dueCards}
              isEditing={editingCategory === name}
              onEdit={() => {
                setEditingCategory(name);
                setNewCategoryName(name);
                setError('');
              }}
              onDelete={() => onDeleteCategory(name)}
              onSave={handleEditSubmit}
              onCancel={() => {
                setEditingCategory(null);
                setNewCategoryName('');
                setError('');
              }}
              newName={newCategoryName}
              setNewName={setNewCategoryName}
              setCategoryToDelete={setCategoryToDelete}
            />
          ))}
        </AnimatePresence>

        {filteredCategories.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-12"
          >
            <div className="bg-amber-50 dark:bg-amber-900/30 w-16 h-16 rounded-full flex items-center 
                         justify-center mx-auto mb-4">
              <Search className="w-6 h-6 text-amber-400" />
            </div>
            <h3 className="text-lg font-medium text-amber-800 dark:text-amber-200 mb-2">
              No matching categories
            </h3>
            <p className="text-amber-600 dark:text-amber-400">
              Try adjusting your search term
            </p>
          </motion.div>
        )}
      </div>

      {/* Tips Section */}
      {categories.length > 0 && (
        <div className="p-4 bg-amber-50 dark:bg-amber-900/30 rounded-xl border border-amber-100 dark:border-amber-900/50">
          <div className="flex items-start gap-3">
            <div className="p-2 bg-amber-100 dark:bg-amber-900/50 rounded-lg shrink-0">
              <Info className="w-4 h-4 text-amber-600 dark:text-amber-400" />
            </div>
            <div>
              <h4 className="text-sm font-medium text-amber-800 dark:text-amber-200">Quick Tips</h4>
              <ul className="mt-2 text-sm text-amber-600 dark:text-amber-400 space-y-1">
                <li className="flex items-center gap-2">
                  <span className="w-1 h-1 bg-amber-400 dark:bg-amber-500 rounded-full" />
                  Categories help organize your flashcards by topic
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1 h-1 bg-amber-400 dark:bg-amber-500 rounded-full" />
                  The General category is default and cannot be modified
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1 h-1 bg-amber-400 dark:bg-amber-500 rounded-full" />
                  Deleting a category will move its cards to General
                </li>
              </ul>
              </div>
          </div>
        </div>
      )}
      <DeleteConfirmationDialog
        isOpen={!!categoryToDelete}
        onClose={() => setCategoryToDelete(null)}
        onConfirm={handleDelete}
        title="Delete Category"
        message="All cards in this category will be moved to General. This action cannot be undone."
        itemName={categoryToDelete}
        isLoading={deleteLoading}
        setCategoryToDelete={setCategoryToDelete}
      />
</>
    )}
    </div>
  );
};

export default CategoryManagerView;