import React, { useState, useRef, useEffect } from 'react';
import { 
  LogOut, 
  Settings, 
  User, 
  Menu, 
  X, 
  ChevronDown, 
  BookOpen, 
  BarChart,
  Trophy,
  Library,
  FolderOpen,
  MessageCircle,
  Shield,
  Sun,
  Moon
} from 'lucide-react';
import { Button } from './ui/Button';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';

const Navbar = ({ 
  user, 
  setView, 
  setAuthView, 
  handleSignOut,
  setShowFlashcardManager, 
  setShowCategoryManager,
  logo
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeView, setActiveView] = useState('dashboard');
  const [isSparkleAnimating, setIsSparkleAnimating] = useState(false);
  const { darkMode, toggleDarkMode } = useTheme();

  const handleViewChange = (view) => {
    setActiveView(view);
    setView(view);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-md sticky top-0 z-40 border-b border-amber-100 dark:border-amber-900/30">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
<motion.div 
  whileHover={{ scale: 1.02 }}
  className="flex items-center gap-4 cursor-pointer" 
  onClick={() => {handleViewChange('welcome'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
  onHoverStart={() => setIsSparkleAnimating(true)}
  onHoverEnd={() => setIsSparkleAnimating(false)}
>
  <div className="w-12 h-12 bg-gradient-to-br from-amber-50 to-amber-100 dark:from-amber-900/50 dark:to-amber-800/50 rounded-xl p-1.5 shadow-sm">
    <img 
      src={logo} 
      alt="RecallPeak Logo" 
      className="w-full h-full object-contain"
    />
  </div>
  <div className="relative inline-block">
    <span className="text-2xl font-bold bg-gradient-to-r from-amber-700 to-amber-900 dark:from-amber-400 dark:to-amber-300 bg-clip-text text-transparent">
      RecallPeak
    </span>
    <span className="absolute -top-3 -right-4 text-amber-400 dark:text-amber-500">
      <motion.div
        animate={isSparkleAnimating ? {
          rotate: [0, 15, -15, 0],
          scale: [1, 1.2, 1],
        } : {
          rotate: 0,
          scale: 1,
        }}
        transition={{
          duration: 2,
          repeat: isSparkleAnimating ? Infinity : 0,
          ease: "easeInOut"
        }}
      >
        ✨
      </motion.div>
    </span>
  </div>
</motion.div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-2">
            <Button
              onClick={toggleDarkMode}
              className="p-2 hover:bg-amber-50 dark:hover:bg-amber-900/20 rounded-lg"
              aria-label="Toggle dark mode"
            >
              {darkMode ? (
                <Sun className="w-5 h-5 text-amber-400" />
              ) : (
                <Moon className="w-5 h-5 text-amber-600" />
              )}
            </Button>

            {user && (
              <>
                <NavItem 
                  icon={BookOpen} 
                  label="Study" 
                  onClick={() => {handleViewChange('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                  active={activeView === 'dashboard'}
                  darkMode={darkMode}
                />
                <NavItem 
                  icon={BarChart} 
                  label="Progress" 
                  onClick={() => {handleViewChange('progress'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                  active={activeView === 'progress'}
                  darkMode={darkMode}
                />
                <NavItem 
                  icon={MessageCircle} 
                  label="Feedback" 
                  onClick={() => {handleViewChange('feedback'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                  active={activeView === 'feedback'}
                  darkMode={darkMode}
                />
              </>
            )}
          </div>

          {/* Auth Section */}
          <div className="hidden md:flex items-center gap-4">
            {user ? (
              <ProfileMenu user={user} handleSignOut={handleSignOut} setView={setView} darkMode={darkMode} />
            ) : (
              <div className="flex items-center gap-3">
                <Button
                  onClick={() => {
                    setAuthView('signin');
                    setView('auth');
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  variant="ghost"
                  className="px-4 py-2 text-amber-600 dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-300 hover:bg-amber-50/60 dark:hover:bg-amber-900/20"
                >
                  Sign In
                </Button>
                <Button
                  onClick={() => {
                    setAuthView('signup');
                    setView('auth');
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  className="px-4 py-2 bg-gradient-to-r from-amber-500 to-amber-600 dark:from-amber-600 dark:to-amber-700 text-white hover:from-amber-600 hover:to-amber-700 dark:hover:from-amber-700 dark:hover:to-amber-800 rounded-lg shadow-sm hover:shadow-md transition-all"
                >
                  Get Started
                </Button>
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <Button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              variant="ghost"
              className="p-2"
            >
              {isMobileMenuOpen ? (
                <X className="w-6 h-6 text-amber-800 dark:text-amber-200" />
              ) : (
                <Menu className="w-6 h-6 text-amber-800 dark:text-amber-200" />
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden overflow-hidden"
          >
            <div className="px-4 py-4 space-y-2 bg-white dark:bg-gray-800 border-t border-amber-100 dark:border-amber-900/30">
              <Button
                onClick={toggleDarkMode}
                className="w-full flex items-center gap-2 px-4 py-2 text-amber-600 dark:text-amber-400 hover:bg-amber-50 dark:hover:bg-amber-900/20 rounded-lg"
              >
                {darkMode ? (
                  <>
                    <Sun className="w-4 h-4" />
                    <span>Light Mode</span>
                  </>
                ) : (
                  <>
                    <Moon className="w-4 h-4" />
                    <span>Dark Mode</span>
                  </>
                )}
              </Button>

              {user ? (
                <>
                  <div className="px-4 py-3 mb-4 bg-amber-50 dark:bg-amber-900/20 rounded-xl">
                    <div className="text-sm text-amber-600 dark:text-amber-400">Signed in as</div>
                    <div className="font-medium text-amber-800 dark:text-amber-200">{user.name}</div>
                  </div>
                  <NavItem 
                    icon={BookOpen} 
                    label="Study" 
                    onClick={() => {handleViewChange('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                    active={activeView === 'dashboard'}
                    darkMode={darkMode}
                  />
                  <NavItem 
                    icon={BarChart} 
                    label="Progress" 
                    onClick={() => {handleViewChange('progress'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                    active={activeView === 'progress'}
                  />
                  {/* <NavItem 
                    icon={Trophy} 
                    label="Achievements" 
                    onClick={() => handleViewChange('achievements')}
                    active={activeView === 'achievements'}
                  /> */}
                  <NavItem 
                    icon={Settings} 
                    label="Settings" 
                    onClick={() => {handleViewChange('settings'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                    active={activeView === 'settings'}
                  />
                  <NavItem 
                    icon={MessageCircle} 
                    label="Feedback" 
                    onClick={() => {handleViewChange('feedback'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                    active={activeView === 'feedback'}
                  />
                  <Button
                    onClick={handleSignOut}
                    className="w-full flex items-center gap-2 px-4 py-2 mt-4 text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg"
                  >
                    <LogOut className="w-4 h-4" />
                    <span className="font-medium">Sign Out</span>
                  </Button>
                </>
              ) : (
                <div className="space-y-2">
                  <Button
                    onClick={() => {
                      setAuthView('signin');
                      setView('auth');
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      setIsMobileMenuOpen(false);
                    }}
                    variant="ghost"
                    className="w-full px-4 py-2 text-amber-600 dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-300 hover:bg-amber-50 dark:hover:bg-amber-900/20"
                  >
                    Sign In
                  </Button>
                  <Button
                    onClick={() => {
                      setAuthView('signup');
                      setView('auth');
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      setIsMobileMenuOpen(false);
                    }}
                    className="w-full px-4 py-2 bg-gradient-to-r from-amber-500 to-amber-600 dark:from-amber-600 dark:to-amber-700 text-white hover:from-amber-600 hover:to-amber-700 dark:hover:from-amber-700 dark:hover:to-amber-800"
                  >
                    Get Started
                  </Button>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

const NavItem = ({ icon: Icon, label, onClick, active = false, darkMode }) => (
  <Button
    onClick={onClick}
    variant="ghost"
    className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all
      ${active 
        ? 'text-amber-800 dark:text-amber-200 bg-amber-50/80 dark:bg-amber-900/20' 
        : 'text-amber-600 dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 hover:bg-amber-50/60 dark:hover:bg-amber-900/20'
      }`}
  >
    <Icon className="w-4 h-4" />
    <span className="font-medium">{label}</span>
  </Button>
);

const ProfileMenu = ({ user, handleSignOut, setView, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const onSignOut = () => {
    handleSignOut(); // This will now handle both the signout and navigation
    setIsOpen(false);
  };

  const handleNavigation = (view) => {
    setView(view);
    setIsOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="relative" ref={menuRef}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        variant="ghost"
        className={`flex items-center gap-3 px-4 py-2 rounded-xl transition-all duration-200
                   ${isOpen 
                     ? 'bg-amber-50 dark:bg-amber-900/20' 
                     : 'hover:bg-amber-50/60 dark:hover:bg-amber-900/20'}`}
      >
        <div className="w-9 h-9 rounded-full bg-gradient-to-br from-amber-100 to-amber-200 
                      dark:from-amber-700 dark:to-amber-600 flex items-center justify-center 
                      transition-transform duration-200"
        >
          <span className="text-amber-800 dark:text-amber-200 font-semibold">
            {user.name[0].toUpperCase()}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium text-amber-800 dark:text-amber-200">
            {user.name}
          </span>
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronDown className="w-4 h-4 text-amber-600 dark:text-amber-400" />
          </motion.div>
        </div>
      </Button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: -10 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: -10 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="absolute right-0 mt-2 w-64 z-50"
          >
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg 
                          border border-amber-100 dark:border-amber-900/30 
                          overflow-hidden backdrop-blur-sm"
            >
              <div className="p-4 border-b border-amber-100 dark:border-amber-900/30
                            bg-gradient-to-br from-amber-50/50 to-transparent 
                            dark:from-amber-900/20 dark:to-transparent"
              >
                <div className="text-sm text-amber-600 dark:text-amber-400">
                  Signed in as
                </div>
                <div className="font-medium text-amber-800 dark:text-amber-200 truncate">
                  {user.email}
                </div>
              </div>
              
              <div className="p-2 space-y-1">
                {/* Management Section */}
                <div className="px-3 py-2">
                  <div className="text-xs font-medium text-amber-500 dark:text-amber-500 mb-2">
                    Management
                  </div>
                  <div className="space-y-1">
                    <Button
                      variant="ghost"
                      className="w-full flex items-center gap-2 px-4 py-2.5 text-amber-600 
                               dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 
                               hover:bg-amber-50 dark:hover:bg-amber-900/20 rounded-lg
                               transition-colors duration-200"
                      onClick={() => handleNavigation('manage-cards')}
                    >
                      <Library className="w-4 h-4" />
                      Manage Cards
                    </Button>
                    <Button
                      variant="ghost"
                      className="w-full flex items-center gap-2 px-4 py-2.5 text-amber-600 
                               dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 
                               hover:bg-amber-50 dark:hover:bg-amber-900/20 rounded-lg
                               transition-colors duration-200"
                      onClick={() => handleNavigation('manage-categories')}
                    >
                      <FolderOpen className="w-4 h-4" />
                      Manage Categories
                    </Button>
                  </div>
                </div>

                {/* Settings & Sign Out Section */}
                <div className="border-t border-amber-100 dark:border-amber-900/30 px-3 py-2">
                  <div className="text-xs font-medium text-amber-500 dark:text-amber-500 mb-2">
                    Account
                  </div>
                  <div className="space-y-1">
                    <Button
                      variant="ghost"
                      className="w-full flex items-center gap-2 px-4 py-2.5 text-amber-600 
                               dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 
                               hover:bg-amber-50 dark:hover:bg-amber-900/20 rounded-lg
                               transition-colors duration-200"
                      onClick={() => handleNavigation('settings')}
                    >
                      <Settings className="w-4 h-4" />
                      Settings
                    </Button>
                    <Button
                      variant="ghost"
                      className="w-full flex items-center gap-2 px-4 py-2.5 text-red-600 
                               dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 
                               hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg
                               transition-colors duration-200"
                      onClick={onSignOut}
                    >
                      <LogOut className="w-4 h-4" />
                      Sign Out
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;