import React, { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { 
  LineChart, 
  Line, 
  BarChart, 
  Bar, 
  PieChart, 
  Pie, 
  Cell,
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from 'recharts';
import { 
  Calendar,
  Brain,
  Target,
  Clock,
  TrendingUp,
  CheckCircle,
  Award,
  BookOpen,
  ChevronUp,
  Zap,
  Flame,
  Trophy,
  Timer,
  Activity,
  Star,
  Crown,
  Gem,
  Medal,
  Heart
} from 'lucide-react';
import { Button } from '../components/ui/Button';
import { getDueCount } from '../utils/dueDate';

// Reusable Components
const Card = ({ title, value, description, icon: Icon, trend, accentColor = "amber" }) => (
  <motion.div
    whileHover={{ y: -2, scale: 1.01 }}
    className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-amber-100 
               dark:border-gray-700 hover:shadow-lg transition-all duration-300"
  >
    <div className="flex items-start justify-between">
      <div>
        <p className="text-sm text-amber-600 dark:text-amber-400">{title}</p>
        <p className="text-3xl font-bold text-amber-900 dark:text-amber-100 mt-1">
          {value}
          {trend && (
            <span className={`text-sm ml-2 ${
              trend > 0 ? 'text-green-500' : trend < 0 ? 'text-red-500' : 'text-gray-500'
            }`}>
              {trend > 0 ? '↑' : trend < 0 ? '↓' : '•'} {Math.abs(trend)}%
            </span>
          )}
        </p>
        <p className="text-sm text-amber-500 dark:text-amber-500 mt-1">{description}</p>
      </div>
      <div className="p-3 bg-amber-50 dark:bg-amber-900/30 rounded-lg">
        <Icon className="w-6 h-6 text-amber-600 dark:text-amber-400" />
      </div>
    </div>
  </motion.div>
);

const ChartContainer = ({ title, children, info }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-amber-100 
               dark:border-gray-700 hover:shadow-lg transition-all duration-300"
  >
    <div className="flex items-start justify-between mb-6">
      <div>
        <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-200">
          {title}
        </h3>
        {info && (
          <p className="text-sm text-amber-500 dark:text-amber-400 mt-1">
            {info}
          </p>
        )}
      </div>
    </div>
    {children}
  </motion.div>
);

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload) return null;

  return (
    <div className="bg-white dark:bg-gray-800 p-3 border border-amber-100 dark:border-gray-700 
                    rounded-lg shadow-lg">
      <p className="text-sm font-medium text-amber-900 dark:text-amber-100">{label}</p>
      {payload.map((item, index) => (
        <p key={index} className="text-sm text-amber-600 dark:text-amber-400">
          {item.name}: {item.value}
        </p>
      ))}
    </div>
  );
};

const Achievement = ({ icon: Icon, title, description, progress, unlocked }) => (
  <div className={`p-4 rounded-lg border ${
    unlocked 
      ? 'bg-amber-50 dark:bg-amber-900/30 border-amber-200 dark:border-amber-800' 
      : 'bg-gray-50 dark:bg-gray-900/30 border-gray-200 dark:border-gray-800'
  }`}>
    <div className="flex items-center gap-3 mb-2">
      <div className={`p-2 rounded-full ${
        unlocked 
          ? 'bg-amber-100 dark:bg-amber-800' 
          : 'bg-gray-100 dark:bg-gray-800'
      }`}>
        <Icon className={`w-5 h-5 ${
          unlocked 
            ? 'text-amber-600 dark:text-amber-400' 
            : 'text-gray-400 dark:text-gray-600'
        }`} />
      </div>
      <div>
        <h4 className={`font-medium ${
          unlocked 
            ? 'text-amber-800 dark:text-amber-200' 
            : 'text-gray-400 dark:text-gray-600'
        }`}>{title}</h4>
        <p className="text-xs text-amber-500 dark:text-amber-500">{description}</p>
      </div>
    </div>
    {progress && (
      <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
        <div
          className="bg-amber-500 dark:bg-amber-600 h-2 rounded-full transition-all duration-300"
          style={{ width: `${progress}%` }}
        />
      </div>
    )}
  </div>
);

const TimeRangeSelector = ({ value, onChange }) => (
  <div className="flex gap-2 p-1 bg-amber-50 dark:bg-gray-700 rounded-lg">
    {['week', 'month', 'year'].map((range) => (
      <button
        key={range}
        onClick={() => onChange(range)}
        className={`px-4 py-2 rounded-lg transition-colors ${
          value === range
            ? 'bg-white dark:bg-gray-800 text-amber-600 dark:text-amber-400 shadow-sm'
            : 'text-amber-600 dark:text-amber-400 hover:bg-white/50 dark:hover:bg-gray-800/50'
        }`}
      >
        {range.charAt(0).toUpperCase() + range.slice(1)}
      </button>
    ))}
  </div>
);

const ProgressView = ({ cards = [], isPremium = false }) => {
  const [timeRange, setTimeRange] = useState('week');
  const [selectedMetric, setSelectedMetric] = useState('accuracy');

  // Helper functions
  const calculateSkillLevel = (cards, skillType) => {
    // Implement actual skill calculation logic
    return Math.floor(Math.random() * 100);
  };

  const calculateLearningStreak = (cards) => {
    // Implement actual streak calculation logic
    return {
      current: 7,
      longest: 15,
      thisMonth: 22,
      averageReviewsPerDay: 12
    };
  };

  const generateAchievements = (cards) => {
    return [
      {
        icon: Star,
        title: 'Quick Learner',
        description: 'Review 50 cards in one day',
        progress: 75,
        unlocked: true
      },
      {
        icon: Flame,
        title: '7-Day Streak',
        description: 'Study every day for a week',
        progress: 100,
        unlocked: true
      },
      {
        icon: Crown,
        title: 'Master Mind',
        description: 'Get 100 cards to mastery level',
        progress: 45,
        unlocked: false
      },
      {
        icon: Medal,
        title: 'Speed Demon',
        description: 'Complete 20 reviews in 5 minutes',
        progress: 60,
        unlocked: false
      }
    ];
  };

  const stats = useMemo(() => {
    if (!Array.isArray(cards)) return {
      totalCards: 0,
      masteredCards: 0,
      dueToday: 0,
      boxDistribution: [],
      categoryDistribution: [],
      reviewProgress: [],
      studyTimeData: [],
      skillLevels: {},
      learningStreak: {},
      achievements: []
    };

    const dueToday = getDueCount(cards);
    const boxCounts = cards.reduce((acc, card) => {
      acc[card.box] = (acc[card.box] || 0) + 1;
      return acc;
    }, {});

    const categoryData = cards.reduce((acc, card) => {
      acc[card.category] = (acc[card.category] || 0) + 1;
      return acc;
    }, {});

    // Calculate review progress
    const today = new Date();
    const reviewProgress = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      const dateStr = date.toLocaleDateString('en-US', { weekday: 'short' });
      const count = cards.filter(card => {
        const reviewDate = new Date(card.last_review_date);
        return reviewDate.toDateString() === date.toDateString();
      }).length;
      return { date: dateStr, reviews: count };
    }).reverse();

    // Premium Stats
    const studyTimeData = Array.from({ length: 7 }, (_, i) => ({
      day: new Date(Date.now() - i * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { weekday: 'short' }),
      minutes: Math.floor(Math.random() * 60) // Replace with actual study time tracking
    })).reverse();

    const skillLevels = {
      memorization: calculateSkillLevel(cards, 'memorization'),
      consistency: calculateSkillLevel(cards, 'consistency'),
      speed: calculateSkillLevel(cards, 'speed'),
      retention: calculateSkillLevel(cards, 'retention'),
      adaptability: calculateSkillLevel(cards, 'adaptability')
    };

    const learningStreak = calculateLearningStreak(cards);
    const achievements = generateAchievements(cards);

    return {
      totalCards: cards.length,
      masteredCards: cards.filter(card => card.box === 5).length,
      dueToday,
      boxDistribution: Object.entries(boxCounts).map(([box, count]) => ({
        box: `Box ${box}`,
        count
      })),
      categoryDistribution: Object.entries(categoryData).map(([category, count]) => ({
        category,
        count
      })),
      reviewProgress,
      studyTimeData,
      skillLevels,
      learningStreak,
      achievements
    };
  }, [cards, timeRange]);

  const COLORS = ['#f59e0b', '#fbbf24', '#fcd34d', '#fde68a', '#fef3c7'];

  const calculateAccuracy = () => {
    const cardsWithHistory = cards.filter(card => card.box > 1);
    if (cardsWithHistory.length === 0) return 0;
    
    const accuracy = (cardsWithHistory.length / cards.length) * 100;
    return Math.round(accuracy);
  };

  // Basic Stats Section
  const BasicStats = () => (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card
          title="Total Cards"
          value={stats.totalCards}
          description="Total flashcards created"
          icon={Brain}
        />
        <Card
          title="Mastered"
          value={`${Math.round((stats.masteredCards / stats.totalCards) * 100)}%`}
          description={`${stats.masteredCards} cards mastered`}
          icon={Award}
        />
        <Card
          title="Due Today"
          value={stats.dueToday}
          description="Cards to review"
          icon={Clock}
        />
        <Card
          title="Overall Accuracy"
          value={`${calculateAccuracy()}%`}
          description="Average success rate"
          icon={CheckCircle}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <ChartContainer 
          title="Review Activity" 
          info="Your review activity over the past week"
        >
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={stats.reviewProgress}>
                <CartesianGrid 
                  strokeDasharray="3 3" 
                  stroke="#fde68a" 
                  className="dark:stroke-gray-700" 
                />
                <XAxis 
                  dataKey="date" 
                  stroke="#92400e"
                  className="dark:stroke-amber-400" 
                />
                <YAxis 
                  stroke="#92400e"
                  className="dark:stroke-amber-400" 
                />
                <Tooltip content={<CustomTooltip />} />
                <Line 
                  type="monotone" 
                  dataKey="reviews" 
                  stroke="#f59e0b" 
                  strokeWidth={3}
                  dot={{ fill: '#f59e0b', strokeWidth: 2 }}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </ChartContainer>

        <ChartContainer 
          title="Learning Progress" 
          info="Distribution of cards across learning stages"
        >
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={stats.boxDistribution} barSize={40}>
                <CartesianGrid 
                  strokeDasharray="3 3" 
                  stroke="#fde68a"
                  className="dark:stroke-gray-700" 
                />
                <XAxis 
                  dataKey="box" 
                  stroke="#92400e"
                  className="dark:stroke-amber-400" 
                />
                <YAxis 
                  stroke="#92400e"
                  className="dark:stroke-amber-400" 
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar 
                  dataKey="count" 
                  fill="#f59e0b"
                  radius={[4, 4, 0, 0]}
                >
                  {stats.boxDistribution.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      className="dark:opacity-80"
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </ChartContainer>
      </div>
    </>
  );

  // Premium Features Section
  const PremiumFeatures = () => (
    <div className="space-y-8">
      {/* Time Range Selector */}
      <div className="flex justify-end">
        <TimeRangeSelector value={timeRange} onChange={setTimeRange} />
      </div>

      {/* Advanced Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card
          title="Current Streak"
          value={`${stats.learningStreak.current} days`}
          description="Keep the momentum going!"
          icon={Flame}
        />
        <Card
          title="Longest Streak"
          value={`${stats.learningStreak.longest} days`}
          description="Your record streak"
          icon={Trophy}
        />
        <Card
          title="Study Time Today"
          value="45 mins"
          description="Daily learning investment"
          icon={Timer}
        />
        <Card
          title="Reviews/Day"
          value={stats.learningStreak.averageReviewsPerDay}
          description="Daily average"
          icon={Activity}
        />
      </div>

      {/* Learning Skills Radar */}
      <ChartContainer title="Learning Skills Analysis" info="Comprehensive breakdown of your learning abilities">
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <RadarChart cx="50%" cy="50%" outerRadius="80%">
              <PolarGrid stroke="#fde68a" className="dark:stroke-gray-700" />
              <PolarAngleAxis 
                dataKey="subject" 
                stroke="#92400e" 
                className="dark:stroke-amber-400"
              />
              <PolarRadiusAxis stroke="#92400e" className="dark:stroke-amber-400" />
              <Radar
                name="Skills"
                dataKey="value"
                stroke="#f59e0b"
                fill="#f59e0b"
                fillOpacity={0.5}
                data={[
                  { subject: 'Memorization', value: stats.skillLevels.memorization },
                  { subject: 'Consistency', value: stats.skillLevels.consistency },
                  { subject: 'Speed', value: stats.skillLevels.speed },
                  { subject: 'Retention', value: stats.skillLevels.retention },
                  { subject: 'Adaptability', value: stats.skillLevels.adaptability }
                ]}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </ChartContainer>

      {/* Study Time Analytics */}
      <ChartContainer title="Study Time Analytics" info="Track your daily learning investment">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={stats.studyTimeData}>
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke="#fde68a" 
                className="dark:stroke-gray-700"
              />
              <XAxis 
                dataKey="day" 
                stroke="#92400e"
                className="dark:stroke-amber-400"
              />
              <YAxis 
                stroke="#92400e"
                className="dark:stroke-amber-400"
              />
              <Tooltip content={<CustomTooltip />} />
              <Area 
                type="monotone" 
                dataKey="minutes" 
                stroke="#f59e0b" 
                fill="#fde68a"
                className="dark:fill-amber-900/30"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </ChartContainer>

      {/* Achievements Section */}
      <ChartContainer title="Learning Achievements" info="Your learning milestones and badges">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {stats.achievements.map((achievement, index) => (
            <Achievement
              key={index}
              icon={achievement.icon}
              title={achievement.title}
              description={achievement.description}
              progress={achievement.progress}
              unlocked={achievement.unlocked}
            />
          ))}
        </div>
      </ChartContainer>
    </div>
  );

  // Premium Upgrade Banner
  const PremiumBanner = () => (
    <div className="bg-gradient-to-r from-amber-500 to-amber-600 dark:from-amber-600 dark:to-amber-700 
                    p-6 rounded-xl text-white text-center">
      <Trophy className="w-12 h-12 mx-auto mb-4" />
      <h3 className="text-xl font-semibold mb-2">
        Unlock Advanced Analytics
      </h3>
      <p className="text-amber-100 mb-4 max-w-md mx-auto">
        Get detailed insights, study time tracking, achievement system, and more with Premium!
      </p>
      <Button 
        className="bg-white text-amber-600 hover:bg-amber-50 px-6 py-2 rounded-lg 
                   font-medium transition-colors"
      >
        Upgrade to Premium
      </Button>
    </div>
  );

  return (
    <div className="space-y-8 p-6">
      {/* Basic Stats (available to all users) */}
      <BasicStats />

      {/* Premium Content */}
      {isPremium ? (
        <PremiumFeatures />
      ) : (
        <PremiumBanner />
      )}
    </div>
  );
};

export default ProgressView;