import { useState, useEffect } from 'react';
import { API_URL } from '../config/api';

export const useCards = (user) => {

  const getCachedCards = () => {
    try {
      const cached = localStorage.getItem('flashcards');
      return cached ? JSON.parse(cached) : [];
    } catch (error) {
      console.error('Error reading from cache:', error);
      return [];
    }
  };

  const updateCache = (newCards) => {
    try {
      localStorage.setItem('flashcards', JSON.stringify(newCards));
    } catch (error) {
      console.error('Error updating cache:', error);
    }
  };

  const [cards, setCards] = useState(() => getCachedCards());
  const [categories, setCategories] = useState(['General']);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [loading, setLoading] = useState(true);

  // Fetch cards from server and update cache
  const fetchCards = async () => {
    try {
      const response = await fetch(`${API_URL}/flashcards`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCards(data);
      updateCache(data); // Update cache with fresh data
    } catch (error) {
      console.error('Error fetching cards:', error);
      // Fall back to cached data if available
      const cachedData = getCachedCards();
      if (cachedData.length > 0) {
        setCards(cachedData);
      }
    }
  };

  // Fetch both cards and categories when user changes
  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          setLoading(true);
          await Promise.all([fetchCards(), fetchCategories()]);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [user]);

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${API_URL}/categories`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }

      const data = await response.json();
      
      // Ensure 'General' category is always present
      const allCategories = new Set(['General']);
      data.forEach(category => {
        if (typeof category === 'object') {
          allCategories.add(category.name);
        } else {
          allCategories.add(category);
        }
      });

      setCategories(Array.from(allCategories));
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

    const handleAddCard = async (cardData) => {
      try {
        const response = await fetch(`${API_URL}/flashcards`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include',
          body: JSON.stringify(cardData)
        });
        
        if (!response.ok) {
          throw new Error('Failed to add card');
        }
    
        const newCard = await response.json();
        const updatedCards = [...cards, newCard];
        setCards(updatedCards);
        updateCache(updatedCards); // Update cache with new card
        return newCard;
      } catch (error) {
        console.error('Error adding card:', error);
        throw error;
      }
    };

  const handleAddCategory = async (name) => {
    try {
      const response = await fetch(`${API_URL}/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify({ name })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add category');
      }

      const newCategory = await response.json();
      console.log('Added category:', newCategory); // Debug log
      
      setCategories(prev => {
        if (!prev.includes(name)) {
          return [...prev, name];
        }
        return prev;
      });
      
      return newCategory;
    } catch (error) {
      console.error('Error adding category:', error);
      throw error;
    }
  };

  const handleDeleteCategory = async (categoryName) => {
    try {
      if (categoryName === 'General') {
        throw new Error('Cannot delete General category');
      }

      const response = await fetch(`${API_URL}/categories/${encodeURIComponent(categoryName)}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete category');
      }

      const data = await response.json();
      console.log('Delete category response:', data); // Debug log

      // Update cards with the returned updated cards
      if (data.updatedCards) {
        setCards(data.updatedCards);
      }

      // Update categories list
      setCategories(prev => prev.filter(cat => cat !== categoryName));

      // Reset selected category if it was the deleted one
      if (selectedCategory === categoryName) {
        setSelectedCategory('All');
      }

      // Fetch fresh data to ensure everything is in sync
      await Promise.all([
        fetchCards(),
        fetchCategories()
      ]);

    } catch (error) {
      console.error('Error in handleDeleteCategory:', error);
      throw error;
    }
  };

  const handleUpdateCard = async (updatedCard) => {
    try {
      // Optimistically update local state and cache
      const updatedCards = cards.map(card => 
        card.id === updatedCard.id ? updatedCard : card
      );
      setCards(updatedCards);
      updateCache(updatedCards);

      const response = await fetch(`${API_URL}/flashcards/${updatedCard.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify(updatedCard)
      });
      
      if (!response.ok) {
        // Rollback on error
        const originalCards = cards;
        setCards(originalCards);
        updateCache(originalCards);
        throw new Error('Failed to update card');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating card:', error);
      throw error;
    }
  };

  const handleDeleteCard = async (cardId) => {
    try {
      // Optimistically update local state and cache
      const filteredCards = cards.filter(card => card.id !== cardId);
      setCards(filteredCards);
      updateCache(filteredCards);

      const response = await fetch(`${API_URL}/flashcards/${cardId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        // Rollback on error
        const originalCards = cards;
        setCards(originalCards);
        updateCache(originalCards);
        throw new Error('Failed to delete card');
      }
    } catch (error) {
      console.error('Error deleting card:', error);
      throw error;
    }
  };

  const handleEditCard = async (updatedCard) => {
    try {
      const response = await fetch(`${API_URL}/flashcards/${updatedCard.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify(updatedCard)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update card');
      }
  
      const data = await response.json();
      
      // Update the cards state with the edited card
      setCards(prevCards => prevCards.map(card => 
        card.id === updatedCard.id ? data : card
      ));
      
      return data;
    } catch (error) {
      console.error('Error updating card:', error);
      throw error;
    }
  };

  const handleEditCategory = async (oldName, newName) => {
    try {
      const response = await fetch(`${API_URL}/categories/${encodeURIComponent(oldName)}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify({ name: newName })
      });
  
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to update category');
      }
  
      // Update local state
      setCategories(prevCategories => 
        prevCategories.map(cat => cat === oldName ? newName : cat)
      );
  
      // Update category in cards
      setCards(prevCards => 
        prevCards.map(card => 
          card.category === oldName ? { ...card, category: newName } : card
        )
      );
  
      return true;
    } catch (error) {
      console.error('Error updating category:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (cards.length > 0) {
      updateCache(cards);
    }
  }, [cards]);

  return {
    cards,
    setCards,
    categories,
    selectedCategory,
    setSelectedCategory,
    handleAddCard,
    handleUpdateCard,
    handleDeleteCard,
    handleAddCategory,
    handleDeleteCategory,
    handleEditCard,
    handleEditCategory,
    loading
  };
};