// src/components/DrawingPad.js
import React, { useRef, useState, useEffect } from 'react';
import { Palette, Eraser, RotateCcw, Save, Minus, Plus } from 'lucide-react';
import { Button } from './ui/Button';

export const DrawingPad = ({ onSave, initialDrawing = null }) => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [strokeColor, setStrokeColor] = useState('#000000');
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [tool, setTool] = useState('pen'); // 'pen' or 'eraser'

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth * 2;
    canvas.height = canvas.offsetHeight * 2;
    canvas.style.width = `${canvas.offsetWidth}px`;
    canvas.style.height = `${canvas.offsetHeight}px`;

    const context = canvas.getContext('2d');
    context.scale(2, 2);
    context.lineCap = 'round';
    context.strokeStyle = strokeColor;
    context.lineWidth = strokeWidth;
    contextRef.current = context;

    // Load initial drawing if provided
    if (initialDrawing) {
      const img = new Image();
      img.onload = () => {
        context.drawImage(img, 0, 0);
      };
      img.src = initialDrawing;
    }
  }, []);

  useEffect(() => {
    if (contextRef.current) {
      contextRef.current.strokeStyle = tool === 'eraser' ? '#ffffff' : strokeColor;
      contextRef.current.lineWidth = tool === 'eraser' ? strokeWidth * 2 : strokeWidth;
    }
  }, [strokeColor, strokeWidth, tool]);

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    contextRef.current.closePath();
    setIsDrawing(false);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    const imageData = canvas.toDataURL('image/png');
    onSave(imageData);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-4">
      <div className="flex justify-between items-center mb-4 space-x-2">
        <div className="flex items-center space-x-2">
          <input
            type="color"
            value={strokeColor}
            onChange={(e) => setStrokeColor(e.target.value)}
            className="w-8 h-8 rounded cursor-pointer"
            disabled={tool === 'eraser'}
          />
          <Button
            onClick={() => setTool('pen')}
            className={`w-8 h-8 p-1 ${
              tool === 'pen' ? 'bg-amber-500 text-white' : 'bg-white text-amber-800'
            }`}
          >
            <Palette className="w-full h-full" />
          </Button>
          <Button
            onClick={() => setTool('eraser')}
            className={`w-8 h-8 p-1 ${
              tool === 'eraser' ? 'bg-amber-500 text-white' : 'bg-white text-amber-800'
            }`}
          >
            <Eraser className="w-full h-full" />
          </Button>
        </div>
        
        <div className="flex items-center space-x-2">
          <Button
            onClick={() => setStrokeWidth(Math.max(1, strokeWidth - 1))}
            className="w-8 h-8 p-1 bg-white text-amber-800"
          >
            <Minus className="w-full h-full" />
          </Button>
          <span className="w-8 text-center">{strokeWidth}</span>
          <Button
            onClick={() => setStrokeWidth(Math.min(20, strokeWidth + 1))}
            className="w-8 h-8 p-1 bg-white text-amber-800"
          >
            <Plus className="w-full h-full" />
          </Button>
        </div>

        <div className="flex items-center space-x-2">
          <Button
            onClick={clearCanvas}
            className="w-8 h-8 p-1 bg-white text-amber-800"
          >
            <RotateCcw className="w-full h-full" />
          </Button>
          <Button
            onClick={handleSave}
            className="w-8 h-8 p-1 bg-amber-500 text-white"
          >
            <Save className="w-full h-full" />
          </Button>
        </div>
      </div>

      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
        onMouseLeave={finishDrawing}
        className="w-full h-64 border rounded-lg cursor-crosshair bg-white"
      />
    </div>
  );
};