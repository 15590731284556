import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Save, 
  X, 
  PenTool, 
  Image as ImageIcon,
  ChevronDown,
  Pencil,
  Tag,
  Plus,
  Check,
  Loader,
  ArrowLeft,
  RefreshCw,
  AlertCircle 
} from 'lucide-react';
import { Button } from './ui/Button';
import { DrawingPad } from './DrawingPad';

// Success Dialog with responsive buttons
const SuccessDialog = ({ onCreateAnother, onDashboard }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
  >
    <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-md w-full text-center">
      <div className="w-16 h-16 bg-green-100 dark:bg-green-900/30 rounded-full flex items-center justify-center mx-auto mb-4">
        <Check className="w-8 h-8 text-green-600 dark:text-green-400" />
      </div>
      <h3 className="text-xl font-semibold text-amber-800 dark:text-amber-200 mb-2">
        Flashcard Created!
      </h3>
      <p className="text-amber-600 dark:text-amber-400 mb-6">
        Would you like to create another flashcard?
      </p>
      <div className="flex flex-col gap-3 w-full sm:flex-row sm:justify-center">
        <Button
          onClick={onCreateAnother}
          className="w-full sm:w-auto px-4 py-2 bg-amber-500 dark:bg-amber-600 text-white flex items-center justify-center gap-2"
        >
          <RefreshCw className="w-4 h-4" />
          Create Another
        </Button>
        <Button
          onClick={onDashboard}
          className="w-full sm:w-auto px-4 py-2 bg-gray-100 dark:bg-gray-700 text-amber-800 dark:text-amber-200 flex items-center justify-center gap-2"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Dashboard
        </Button>
      </div>
    </div>
  </motion.div>
);

const TextareaWithButtons = ({ 
  value, 
  onChange, 
  placeholder, 
  onDrawingClick, 
  onImageClick, 
  hasDrawing, 
  hasImage, 
  side 
}) => (
  <div className="relative flex flex-col">
    <textarea
      value={value}
      onChange={onChange}
      className="w-full px-4 pt-3 pb-20 sm:pb-12 border border-amber-200 dark:border-amber-900/50 rounded-xl 
               focus:outline-none focus:ring-2 focus:ring-amber-500 min-h-[100px] resize-none
               bg-white dark:bg-gray-800 text-amber-900 dark:text-amber-100
               placeholder:text-amber-400 dark:placeholder:text-amber-500"
      placeholder={placeholder}
    />
    <div className="absolute bottom-2 left-2 right-2">
      <div className="flex flex-col sm:flex-row gap-2 justify-end">
        <Button
          type="button"
          onClick={onDrawingClick}
          disabled={hasImage}
          className="h-8 px-3 bg-amber-50 dark:bg-amber-900/40 text-amber-600 dark:text-amber-400 
                   hover:bg-amber-100 dark:hover:bg-amber-900/60 disabled:opacity-50 w-full sm:w-auto"
        >
          <PenTool className="w-4 h-4 mr-1" />
          {hasDrawing ? 'Edit Drawing' : 'Add Drawing'}
        </Button>
        <Button
          type="button"
          onClick={onImageClick}
          disabled={hasDrawing}
          className="h-8 px-3 bg-amber-50 dark:bg-amber-900/40 text-amber-600 dark:text-amber-400 
                   hover:bg-amber-100 dark:hover:bg-amber-900/60 disabled:opacity-50 w-full sm:w-auto"
        >
          <ImageIcon className="w-4 h-4 mr-1" />
          {hasImage ? 'Change Image' : 'Add Image'}
        </Button>
      </div>
    </div>
  </div>
);

const FlashcardCreator = ({ 
  mode = 'add',
  initialCard = null,
  categories, 
  onSave, 
  onCancel, 
  handleAddCategory,
  setView 
}) => {
  const categoryNames = categories.map(cat => 
      typeof cat === 'object' ? cat.name : cat
    );

    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [formData, setFormData] = useState(
      initialCard || {
        question: '',
        answer: '',
        category: categories[0] || 'General',
        frontDrawing: null,
        frontImage: null,
        backDrawing: null,
        backImage: null
      }
    );

  const [showDrawingPad, setShowDrawingPad] = useState(false);
  const [drawingSide, setDrawingSide] = useState(null);
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [error, setError] = useState('');

  const resetForm = () => {
    setFormData({
      question: '',
      answer: '',
      category: categories[0] || 'General',
      frontDrawing: null,
      frontImage: null,
      backDrawing: null,
      backImage: null
    });
  };

  const handleNewCategory = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!newCategory.trim()) {
      setError('Category name cannot be empty');
      return;
    }

    if (categoryNames.includes(newCategory.trim())) {
      setError('Category already exists');
      return;
    }

    try {
      await handleAddCategory(newCategory.trim());
      setFormData({ ...formData, category: newCategory.trim() });
      setNewCategory('');
      setIsCreatingCategory(false);
      setError('');
    } catch (error) {
      setError(error.message || 'Failed to create category');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate the form
    const hasQuestionContent = formData.frontDrawing || formData.frontImage || formData.question.trim();
    const hasAnswerContent = formData.backDrawing || formData.backImage || formData.answer.trim();

    if (!hasQuestionContent) {
      setError('Question requires either text, drawing, or image');
      return;
    }

    if (!hasAnswerContent) {
      setError('Answer requires either text, drawing, or image');
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      await onSave(formData);
      setShowSuccess(true);
    } catch (error) {
      setError(error.message || 'Error saving flashcard');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAnother = () => {
    setShowSuccess(false);
    resetForm();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBackToDashboard = () => {
    setView('dashboard');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSaveDrawing = (drawingData) => {
    if (drawingSide === 'front') {
      setFormData({
        ...formData,
        frontDrawing: drawingData,
        frontImage: null // Clear any existing image
      });
    } else {
      setFormData({
        ...formData,
        backDrawing: drawingData,
        backImage: null // Clear any existing image
      });
    }
    setShowDrawingPad(false);
    setDrawingSide(null);
  };

  const handleImageUpload = async (event, side) => {
    const file = event.target.files[0];
    if (!file) return;
  
    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }
  
    if (file.size > 5 * 1024 * 1024) {
      setError('Image must be less than 5MB');
      return;
    }
  
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (side === 'front') {
          setFormData({
            ...formData,
            frontImage: reader.result,
            frontDrawing: null // Clear any existing drawing
          });
        } else {
          setFormData({
            ...formData,
            backImage: reader.result,
            backDrawing: null // Clear any existing drawing
          });
        }
      };
    } catch (error) {
      console.error('Error processing image:', error);
      setError('Error processing image. Please try again.');
    }
    
    event.target.value = '';
  };

  return (
    <div className="w-full max-w-2xl mx-auto">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-4 sm:p-6 border border-amber-100 dark:border-amber-900/50">
        {error && (
          <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg flex items-center gap-2 text-red-600 dark:text-red-400">
            <AlertCircle className="w-4 h-4 flex-shrink-0" />
            <span className="text-sm">{error}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Question Field */}
          <div className="space-y-2">
            <label className="block text-sm font-semibold text-amber-800 dark:text-amber-200">
              Question
            </label>
            <TextareaWithButtons
              value={formData.question}
              onChange={(e) => setFormData({ ...formData, question: e.target.value })}
              placeholder="Enter text or add visual content below"
              onDrawingClick={() => {
                setDrawingSide('front');
                setShowDrawingPad(true);
              }}
              onImageClick={() => document.getElementById('front-image-upload').click()}
              hasDrawing={formData.frontDrawing !== null}
              hasImage={formData.frontImage !== null}
              side="front"
            />

            <input
              type="file"
              id="front-image-upload"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, 'front')}
              className="hidden"
            />
            
            {/* Question Preview Section */}
            {(formData.frontDrawing || formData.frontImage) && (
              <div className="mt-3 bg-amber-50/50 dark:bg-amber-900/20 rounded-xl p-3">
                <div className="grid grid-cols-1 gap-4">
                  {formData.frontDrawing && (
                    <div className="relative group">
                      <img
                        src={formData.frontDrawing}
                        alt="Question drawing"
                        className="w-full h-48 object-contain rounded-lg"
                      />
                      <Button
                        onClick={() => setFormData({ ...formData, frontDrawing: null })}
                        className="absolute top-2 right-2 bg-red-500 text-white"
                      >
                        Remove Drawing
                      </Button>
                    </div>
                  )}
                  {formData.frontImage && (
                    <div className="relative group">
                      <img
                        src={formData.frontImage}
                        alt="Question image"
                        className="w-full h-48 object-contain rounded-lg"
                      />
                      <Button
                        onClick={() => setFormData({ ...formData, frontImage: null })}
                        className="absolute top-2 right-2 bg-red-500 text-white"
                      >
                        Remove Image
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Answer Field */}
          <div className="space-y-2">
            <label className="block text-sm font-semibold text-amber-800 dark:text-amber-200">
              Answer
            </label>
            <TextareaWithButtons
              value={formData.answer}
              onChange={(e) => setFormData({ ...formData, answer: e.target.value })}
              placeholder="Enter text or add visual content below"
              onDrawingClick={() => {
                setDrawingSide('back');
                setShowDrawingPad(true);
              }}
              onImageClick={() => document.getElementById('back-image-upload').click()}
              hasDrawing={formData.backDrawing !== null}
              hasImage={formData.backImage !== null}
              side="back"
            />

            <input
              type="file"
              id="back-image-upload"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, 'back')}
              className="hidden"
            />

            {/* Answer Preview Section */}
            {(formData.backDrawing || formData.backImage) && (
              <div className="mt-3 bg-amber-50/50 dark:bg-amber-900/20 rounded-xl p-3">
                <div className="grid grid-cols-1 gap-4">
                  {formData.backDrawing && (
                    <div className="relative group">
                      <img
                        src={formData.backDrawing}
                        alt="Answer drawing"
                        className="w-full h-48 object-contain rounded-lg"
                      />
                      <Button
                        onClick={() => setFormData({ ...formData, backDrawing: null })}
                        className="absolute top-2 right-2 bg-red-500 text-white"
                      >
                        Remove Drawing
                      </Button>
                    </div>
                  )}
                  {formData.backImage && (
                    <div className="relative group">
                      <img
                        src={formData.backImage}
                        alt="Answer image"
                        className="w-full h-48 object-contain rounded-lg"
                      />
                      <Button
                        onClick={() => setFormData({ ...formData, backImage: null })}
                        className="absolute top-2 right-2 bg-red-500 text-white"
                      >
                        Remove Image
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Category Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-semibold text-amber-800 dark:text-amber-200">
              Category
            </label>
            {isCreatingCategory ? (
              <div className="space-y-2">
                <div className="flex flex-col sm:flex-row gap-2">
                  <input
                    type="text"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    placeholder="Enter new category name"
                    className="flex-1 px-4 py-2 border border-amber-200 dark:border-amber-900/50 rounded-xl 
                             focus:outline-none focus:ring-2 focus:ring-amber-500
                             bg-white dark:bg-gray-800 text-amber-900 dark:text-amber-100
                             placeholder:text-amber-400 dark:placeholder:text-amber-500"
                    autoFocus
                  />
                  <div className="flex gap-2">
                    <Button
                      type="button"
                      onClick={handleNewCategory}
                      className="flex-1 sm:flex-none px-4 py-2 bg-amber-500 dark:bg-amber-600 text-white rounded-xl 
                               hover:bg-amber-600 dark:hover:bg-amber-700"
                    >
                      Add
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setIsCreatingCategory(false);
                        setNewCategory('');
                        setError('');
                      }}
                      className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-xl"
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col sm:flex-row gap-2">
                <div className="relative flex-1">
                  <select
                    value={formData.category}
                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                    className="w-full px-4 py-3 border border-amber-200 dark:border-amber-900/50 rounded-xl 
                             appearance-none bg-white dark:bg-gray-800 focus:outline-none focus:ring-2 
                             focus:ring-amber-500 text-amber-900 dark:text-amber-100"
                  >
                    {categoryNames.map(category => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                  <div className="absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none flex items-center">
                    <Tag className="w-4 h-4 text-amber-600 dark:text-amber-400 mr-2" />
                    <ChevronDown className="w-4 h-4 text-amber-600 dark:text-amber-400" />
                  </div>
                </div>
                <Button
                  type="button"
                  onClick={() => setIsCreatingCategory(true)}
                  className="w-full sm:w-auto px-4 py-2 bg-amber-500 dark:bg-amber-600 text-white rounded-xl 
                           flex items-center justify-center gap-2 hover:bg-amber-600 dark:hover:bg-amber-700"
                >
                  <Plus className="w-4 h-4" />
                  New Category
                </Button>
              </div>
            )}
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            disabled={loading}
            className="w-full py-3 bg-gradient-to-r from-amber-500 to-amber-600 
                    dark:from-amber-600 dark:to-amber-700 text-white rounded-xl
                    flex items-center justify-center gap-2"
          >
            {loading ? (
              <>
                <Loader className="w-5 h-5 animate-spin" />
                <span>Creating Flashcard...</span>
              </>
            ) : (
              <>
                <Save className="w-5 h-5" />
                <span>Save Flashcard</span>
              </>
            )}
          </Button>
        </form>

        {/* Success Dialog - Move outside the main form div */}
        <AnimatePresence>
            {showSuccess && (
                <SuccessDialog
                    onCreateAnother={handleCreateAnother}
                    onDashboard={handleBackToDashboard}
                />
            )}
        </AnimatePresence>

        {/* Drawing Pad Modal */}
        <AnimatePresence>
          {showDrawingPad && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
            >
              <motion.div
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.95 }}
                className="bg-white dark:bg-gray-800 rounded-2xl w-full max-w-3xl shadow-xl"
              >
                <div className="p-4 border-b border-amber-100 dark:border-amber-900/50 
                             flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-200">
                    Add {drawingSide === 'front' ? 'Question' : 'Answer'} Illustration
                  </h3>
                  <Button
                    onClick={() => {
                      setShowDrawingPad(false);
                      setDrawingSide(null);
                    }}
                    className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
                  >
                    <X className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                  </Button>
                </div>
                <div className="p-6">
                  <DrawingPad
                    onSave={handleSaveDrawing}
                    initialDrawing={
                      drawingSide === 'front'
                        ? formData.frontDrawing
                        : formData.backDrawing
                    }
                  />
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default FlashcardCreator;