// src/views/AdminView.js
import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { API_URL } from '../config/api';

const AdminView = ({ setView }) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const fetchFeedbacks = async () => {
    try {
      const response = await fetch(`${API_URL}/feedback/all`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch feedbacks');
      }

      const data = await response.json();
      setFeedbacks(data);
    } catch (error) {
      setError('Error fetching feedbacks');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-8">
        <Button
          onClick={() => {setView('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
          variant="ghost"
          className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
        >
          <ArrowLeft className="w-5 h-5 text-amber-600 dark:text-amber-400" />
        </Button>
        <h1 className="text-2xl font-bold text-amber-800 dark:text-amber-200">Admin Dashboard</h1>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4 text-amber-800 dark:text-amber-200">User Feedbacks</h2>
        
        {loading ? (
          <div className="text-center py-4">Loading...</div>
        ) : error ? (
          <div className="text-red-500 text-center py-4">{error}</div>
        ) : (
          <div className="space-y-4">
            {feedbacks.map(feedback => (
              <div 
                key={feedback.id} 
                className="border border-amber-100 dark:border-gray-700 rounded-lg p-4"
              >
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <p className="font-medium text-amber-800 dark:text-amber-200">
                      {feedback.user_name}
                    </p>
                    <p className="text-sm text-amber-600 dark:text-amber-400">
                      {feedback.user_email}
                    </p>
                  </div>
                  <span className="px-3 py-1 bg-amber-100 dark:bg-amber-900 rounded-full text-sm text-amber-800 dark:text-amber-200">
                    {feedback.type}
                  </span>
                </div>
                <div className="flex items-center mb-2">
                  {'★'.repeat(feedback.rating)}{'☆'.repeat(5-feedback.rating)}
                  <span className="ml-2 text-amber-600 dark:text-amber-400">
                    ({feedback.rating}/5)
                  </span>
                </div>
                <p className="text-gray-700 dark:text-gray-300">
                  {feedback.message}
                </p>
                <p className="text-sm text-amber-500 dark:text-amber-400 mt-2">
                  {new Date(feedback.created_at).toLocaleString()}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminView;