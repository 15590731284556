import React from 'react';
import { motion } from 'framer-motion';
import { X, ArrowLeft } from 'lucide-react';
import FlashcardCreator from '../components/FlashcardCreator';
import { Button } from '../components/ui/Button';

const CardFormView = ({ 
  mode = 'add',
  initialCard = null,
  handleAddCard, 
  handleUpdateCard, 
  handleAddCategory,
  setView,
  categories 
}) => {
  const handleSave = async (formData) => {
    try {
      if (mode === 'edit' && initialCard) {
        const updateData = {
          id: initialCard.id,
          question: formData.question || initialCard.question,
          answer: formData.answer || initialCard.answer,
          category: formData.category || initialCard.category,
          frontDrawing: formData.frontDrawing,
          backDrawing: formData.backDrawing,
          frontImage: formData.frontImage,
          backImage: formData.backImage
        };
        
        await handleUpdateCard(updateData);
        setView('manage-cards');
      } else {
        await handleAddCard(formData);
      }
    } catch (error) {
      console.error('Error saving flashcard:', error);
      throw error;
    }
  };

  const handleClose = () => {
    setView('dashboard');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="min-h-[calc(100vh-8rem)] flex flex-col space-y-4"
    >
      {/* Back to Dashboard Button - Visible on desktop only */}
      <div className="hidden md:block">
        <Button
          onClick={handleClose}
          className="px-4 py-2 bg-white dark:bg-gray-800 text-amber-600 dark:text-amber-400 
                   hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg border border-amber-100 
                   dark:border-amber-900/50 flex items-center gap-2 transition-colors"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Dashboard
        </Button>
      </div>

      <div className="w-full max-w-2xl mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-amber-100 dark:border-amber-900/50 overflow-hidden">
          {/* Header Section */}
          <div className="p-4 md:p-6 border-b border-amber-100 dark:border-amber-900/50">
            <div className="flex items-center justify-between">
              {/* Mobile Back Button */}
              <div className="md:hidden">
                <Button
                  onClick={handleClose}
                  className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
                >
                  <ArrowLeft className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                </Button>
              </div>

              {/* Title */}
              <h2 className="text-xl md:text-2xl font-bold text-amber-800 dark:text-amber-200">
                {mode === 'edit' ? 'Edit Flashcard' : 'Create New Flashcard'}
              </h2>

              {/* Close Button */}
              <Button
                onClick={handleClose}
                className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
              >
                <X className="w-5 h-5 text-amber-600 dark:text-amber-400" />
              </Button>
            </div>
          </div>

          {/* Main Content */}
          <div className="p-4 md:p-6">
            <FlashcardCreator
              mode={mode}
              initialCard={initialCard}
              categories={categories}
              onSave={handleSave}
              onCancel={() => {
                setView('manage-cards');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              handleAddCategory={handleAddCategory}
              setView={setView}
            />
          </div>
        </div>
      </div>

      {/* Mobile Bottom Safe Area */}
      <div className="h-8 md:hidden" />
    </motion.div>
  );
};

export default CardFormView;