import React, { useState } from 'react';
import { 
  User, 
  Mail, 
  Lock, 
  Moon, 
  Sun, 
  Bell, 
  ArrowLeft,
  Save,
  Check,
  Loader,
  AlertCircle
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../components/ui/Button';
import { API_URL } from '../config/api';
import { useTheme } from '../context/ThemeContext';

const SuccessDialog = ({ onClose }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.95 }}
    className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
  >
    <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-md w-full text-center">
      <div className="w-16 h-16 bg-green-100 dark:bg-green-900/30 rounded-full flex items-center justify-center mx-auto mb-4">
        <Check className="w-8 h-8 text-green-600 dark:text-green-400" />
      </div>
      <h3 className="text-xl font-semibold text-amber-800 dark:text-amber-200 mb-2">
        Settings Updated Successfully!
      </h3>
      <p className="text-amber-600 dark:text-amber-400 mb-6">
        Your changes have been saved.
      </p>
      <Button
        onClick={onClose}
        className="px-4 py-2 bg-amber-500 dark:bg-amber-600 text-white flex items-center justify-center gap-2 mx-auto"
      >
        <ArrowLeft className="w-4 h-4" />
        Back to Dashboard
      </Button>
    </div>
  </motion.div>
);

const SettingsView = ({ user, setView }) => {
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState('');
    const { darkMode, toggleDarkMode } = useTheme();
    const [formData, setFormData] = useState({
      name: user?.name || '',
      email: user?.email || '',
      currentPassword: '',
      newPassword: '',
      theme: user?.theme || 'light',
      notifications: user?.notifications ?? true
    });
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError('');
      
      try {
        const response = await fetch(`${API_URL}/auth/settings`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include',
          body: JSON.stringify(formData)
        });
    
        const data = await response.json();
    
        if (!response.ok) {
          throw new Error(data.message || 'Failed to update settings');
        }
    
        localStorage.setItem('user', JSON.stringify(data.user));
        setShowSuccess(true);
        
        // Reset password fields
        setFormData(prev => ({
          ...prev,
          currentPassword: '',
          newPassword: ''
        }));
    
      } catch (error) {
        setError(error.message || 'Error updating settings');
        console.error('Error updating settings:', error);
      } finally {
        setLoading(false);
      }
    };

      const handleBackToDashboard = () => {
        setView('dashboard');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // Reload the page to reflect changes
        window.location.reload();
      };

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-8">
        <Button
          onClick={() => {setView('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
          variant="ghost"
          className="p-2 hover:bg-amber-50 dark:hover:bg-amber-900/20 rounded-lg"
        >
          <ArrowLeft className="w-5 h-5 text-amber-600 dark:text-amber-400" />
        </Button>
        <h1 className="text-2xl font-bold text-amber-800 dark:text-amber-200">Settings</h1>
      </div>

      {/* Main Content */}
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 space-y-8">
        {/* Success Message */}
        {/* {successMessage && (
          <div className="bg-green-50 dark:bg-green-900/20 text-green-600 dark:text-green-400 px-4 py-3 rounded-lg flex items-center gap-2">
            <Check className="w-5 h-5" />
            {successMessage}
          </div>
        )} */}

        {error && (
          <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-4 py-3 rounded-lg flex items-center gap-2">
            <AlertCircle className="w-5 h-5" />
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Profile Section */}
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-amber-800 dark:text-amber-200 flex items-center gap-2">
              <User className="w-5 h-5" />
              Profile Information
            </h2>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-amber-700 dark:text-amber-300 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-2 border border-amber-200 dark:border-amber-700 dark:bg-gray-700 dark:text-amber-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500 dark:focus:ring-amber-400"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-amber-700 dark:text-amber-300 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-4 py-2 border border-amber-200 dark:border-amber-700 dark:bg-gray-700 dark:text-amber-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500 dark:focus:ring-amber-400"
                />
              </div>
            </div>
          </div>

          {/* Security Section */}
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-amber-800 dark:text-amber-200 flex items-center gap-2">
              <Lock className="w-5 h-5" />
              Security
            </h2>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-amber-700 dark:text-amber-300 mb-1">
                  Current Password
                </label>
                <input
                  type="password"
                  value={formData.currentPassword}
                  onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                  className="w-full px-4 py-2 border border-amber-200 dark:border-amber-700 dark:bg-gray-700 dark:text-amber-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500 dark:focus:ring-amber-400"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-amber-700 dark:text-amber-300 mb-1">
                  New Password
                </label>
                <input
                  type="password"
                  value={formData.newPassword}
                  onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                  className="w-full px-4 py-2 border border-amber-200 dark:border-amber-700 dark:bg-gray-700 dark:text-amber-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500 dark:focus:ring-amber-400"
                />
              </div>
            </div>
          </div>

          {/* Preferences Section */}
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-amber-800 dark:text-amber-200 flex items-center gap-2">
              <Sun className="w-5 h-5" />
              Preferences
            </h2>
            
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Bell className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                  <span className="text-amber-800 dark:text-amber-200">Enable Notifications</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.notifications}
                    onChange={(e) => setFormData({ ...formData, notifications: e.target.checked })}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 dark:bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-amber-300 dark:peer-focus:ring-amber-800 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-amber-500"></div>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Moon className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                  <span className="text-amber-800 dark:text-amber-200">Dark Mode</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={darkMode}
                    onChange={() => {
                      toggleDarkMode();
                      setFormData(prev => ({ ...prev, theme: darkMode ? 'light' : 'dark' }));
                    }}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 dark:bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-amber-300 dark:peer-focus:ring-amber-800 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-amber-500"></div>
                </label>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            disabled={loading}
            className="w-full py-3 bg-gradient-to-r from-amber-500 to-amber-600 dark:from-amber-600 dark:to-amber-700 
                     text-white hover:from-amber-600 hover:to-amber-700 dark:hover:from-amber-700 dark:hover:to-amber-800 
                     rounded-xl shadow-sm hover:shadow-md transition-all flex items-center justify-center gap-2"
          >
            {loading ? (
              <>
                <Loader className="w-5 h-5 animate-spin" />
                Saving Changes...
              </>
            ) : (
              <>
                <Save className="w-5 h-5" />
                Save Changes
              </>
            )}
          </Button>
        </form>
      </div>
      {/* Success Dialog */}
      <AnimatePresence>
        {showSuccess && (
          <SuccessDialog onClose={handleBackToDashboard} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default SettingsView;