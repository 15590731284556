// utils/dueDate.js

// Standardized function to check if a card is due
export const isCardDue = (card) => {
    if (!card.next_review_date) return true;
    
    const today = new Date();
    const nextReview = new Date(card.next_review_date);
    
    // Reset time parts to compare dates only
    today.setHours(0, 0, 0, 0);
    nextReview.setHours(0, 0, 0, 0);
    
    return nextReview <= today;
  };
  
  // Get all cards due for review
  export const getDueCards = (cards) => {
    return cards.filter(isCardDue);
  };
  
  // Get the count of due cards
  export const getDueCount = (cards) => {
    return getDueCards(cards).length;
  };