import React from 'react';
import { ArrowLeft, HelpCircle, Book, Brain, Settings, Calendar, Clock, Star, Shield } from 'lucide-react';
import { Button } from '../components/ui/Button';

const FAQSection = ({ title, icon: Icon, children }) => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold text-amber-800 dark:text-amber-200 flex items-center gap-2 mb-4">
      <Icon className="w-6 h-6 text-amber-600 dark:text-amber-400" />
      {title}
    </h2>
    <div className="space-y-6">
      {children}
    </div>
  </div>
);

const FAQItem = ({ question, children }) => (
  <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-amber-100 dark:border-gray-700">
    <h3 className="text-lg font-medium text-amber-800 dark:text-amber-200 mb-3">{question}</h3>
    <div className="text-amber-600 dark:text-amber-400 space-y-2">
      {children}
    </div>
  </div>
);

const FAQView = ({ setView }) => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-8">
        <Button
          onClick={() => {setView('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
          variant="ghost"
          className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
        >
          <ArrowLeft className="w-5 h-5 text-amber-600 dark:text-amber-400" />
        </Button>
        <div>
          <h1 className="text-2xl font-bold text-amber-800 dark:text-amber-200 flex items-center gap-2">
            <HelpCircle className="w-7 h-7" />
            Frequently Asked Questions
          </h1>
          <p className="text-amber-600 dark:text-amber-400 mt-1">
            Find answers to common questions about using RecallPeak
          </p>
        </div>
      </div>

      {/* FAQ Content */}
      <div className="space-y-8">
        <FAQSection title="Getting Started" icon={Book}>
          <FAQItem question="What is RecallPeak?">
            <p>
              RecallPeak is a flashcard application that uses spaced repetition to help you learn and remember information more effectively. It's designed for students, professionals, and anyone looking to enhance their learning process.
            </p>
          </FAQItem>

          <FAQItem question="How does spaced repetition work?">
            <p>
              Spaced repetition is a learning technique that involves reviewing information at gradually increasing intervals. In RecallPeak:
            </p>
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>Cards start in Box 1 with daily reviews</li>
              <li>Correct answers move cards to higher boxes with longer intervals</li>
              <li>Incorrect answers return cards to Box 1</li>
              <li>The system optimizes your review schedule automatically</li>
            </ul>
          </FAQItem>
        </FAQSection>

        <FAQSection title="Features" icon={Brain}>
          <FAQItem question="What types of content can I create?">
            <p>RecallPeak supports various content types for your flashcards:</p>
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>Text-based questions and answers</li>
              <li>Hand-drawn illustrations using our drawing tool</li>
              <li>Uploaded images</li>
              <li>AI-generated flashcards from your study materials</li>
            </ul>
          </FAQItem>

          <FAQItem question="How does the AI flashcard generation work?">
            <p>
              You can upload PDF study materials, and our AI will automatically generate relevant flashcards. The system:
            </p>
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>Analyzes your document for key concepts</li>
              <li>Creates question-answer pairs</li>
              <li>Allows you to generate up to 30 cards per day</li>
              <li>Lets you edit or customize the generated cards</li>
            </ul>
          </FAQItem>
        </FAQSection>

        <FAQSection title="Study System" icon={Calendar}>
          <FAQItem question="How should I organize my flashcards?">
            <p>We recommend these best practices for organizing your cards:</p>
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>Create specific categories for different subjects</li>
              <li>Keep questions clear and focused on one concept</li>
              <li>Use the AI generator for comprehensive topic coverage</li>
              <li>Review your due cards daily for optimal learning</li>
            </ul>
          </FAQItem>

          <FAQItem question="What are the review intervals?">
            <p>The spaced repetition system uses these intervals:</p>
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>Box 1: Review every day</li>
              <li>Box 2: Review every 2 days</li>
              <li>Box 3: Review every 4 days</li>
              <li>Box 4: Review every 8 days</li>
              <li>Box 5: Review every 16 days (Mastered)</li>
            </ul>
          </FAQItem>
        </FAQSection>

        <FAQSection title="Account Management" icon={Settings}>
          <FAQItem question="How do I change my account settings?">
            <p>You can manage your account through the Settings page:</p>
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>Update your profile information</li>
              <li>Change your password</li>
              <li>Toggle dark mode</li>
              <li>Manage notification preferences</li>
            </ul>
          </FAQItem>

          <FAQItem question="Is my data secure?">
            <p>We take several measures to ensure your data security:</p>
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>All data is encrypted in transit and at rest</li>
              <li>Passwords are securely hashed</li>
              <li>Regular security updates and maintenance</li>
              <li>Strict data privacy policies</li>
            </ul>
          </FAQItem>
        </FAQSection>
      </div>
    </div>
  );
};

export default FAQView;