import React, { useState, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Pencil, 
  Trash2, 
  ChevronDown, 
  ChevronUp, 
  AlertCircle,
  FolderOpen,
  Search,
  X,
  Plus,
  Filter,
  Calendar,
  Clock,
  Tag,
  Box,
  Check,
  AlertTriangle,
  ArrowLeft,
  Loader
} from 'lucide-react';
import { Button } from '../components/ui/Button';
import DeleteConfirmationDialog from '../components/DeleteConfirmation';
import { API_URL } from '../config/api';

const CardItem = ({ card, handleEditCard, onDelete, setCardToDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const getDueDateStatus = (date) => {
    const today = new Date();
    const dueDate = new Date(date);
    const diffDays = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));
    
    if (diffDays < 0) return { color: 'red', text: 'Overdue' };
    if (diffDays === 0) return { color: 'orange', text: 'Due today' };
    if (diffDays <= 3) return { color: 'yellow', text: `Due in ${diffDays} days` };
    return { color: 'green', text: `Due in ${diffDays} days` };
  };

  const dueStatus = card.next_review_date ? getDueDateStatus(card.next_review_date) : null;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: -2 }}
      className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-amber-100 dark:border-gray-700 overflow-hidden
                 hover:shadow-md transition-all duration-200"
    >
      <div className="p-4">
        <div className="flex justify-between items-start gap-4">
          <div className="flex-1">
            <div 
              className="text-amber-800 dark:text-amber-200 font-medium cursor-pointer flex items-start gap-2 group"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <div className="p-1 group-hover:bg-amber-50 dark:group-hover:bg-gray-700 rounded-lg transition-colors mt-0.5">
                {isExpanded ? 
                  <ChevronUp className="w-4 h-4 text-amber-600 dark:text-amber-400" /> : 
                  <ChevronDown className="w-4 h-4 text-amber-600 dark:text-amber-400" />
                }
              </div>
              <div>
                <div className="text-amber-900 dark:text-amber-100">{card.question}</div>
                {!isExpanded && (
                  <div className="text-sm text-amber-500 dark:text-amber-400 mt-1 line-clamp-1">
                    {card.answer}
                  </div>
                )}
              </div>
            </div>
            <AnimatePresence>
              {isExpanded && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="mt-4 text-amber-700 dark:text-amber-200 bg-amber-50 dark:bg-amber-900/20 p-4 rounded-lg"
                >
                  {card.answer}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="flex items-center gap-2">
            <Button
              onClick={() => handleEditCard(card)}
              className="p-2 text-amber-600 dark:text-amber-400 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg group
                       transition-colors relative"
              //title="Edit card"
            >
              <Pencil className="w-4 h-4" />
              <span className="absolute -bottom-8 right-0 bg-amber-900 dark:bg-amber-800 text-white text-xs
                             px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                Edit
              </span>
            </Button>
            <Button
              onClick={() => setCardToDelete(card.id)}
              className="p-2 text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg group
                       transition-colors relative"
              //title="Delete card"
            >
              <Trash2 className="w-4 h-4" />
              <span className="absolute -bottom-8 right-0 bg-red-600 dark:bg-red-500 text-white text-xs
                             px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                Delete
              </span>
            </Button>
          </div>
        </div>
        <div className="mt-4 flex items-center gap-3 flex-wrap">
          <div className="flex items-center gap-1.5 text-xs px-2.5 py-1.5 bg-amber-50 dark:bg-amber-900/20 
                         rounded-full text-amber-600 dark:text-amber-400 border border-amber-200 dark:border-amber-700">
            <Box className="w-3 h-3" />
            Box {card.box}
          </div>
          {card.category && (
            <div className="flex items-center gap-1.5 text-xs px-2.5 py-1.5 
                         bg-amber-50 dark:bg-amber-900/20 rounded-full text-amber-600 dark:text-amber-400 border border-amber-200 dark:border-amber-700">
              <Tag className="w-3 h-3" />
              {card.category}
            </div>
          )}
          {dueStatus && (
            <div className={`flex items-center gap-1.5 text-xs px-2.5 py-1.5 
                          bg-${dueStatus.color}-50 dark:bg-${dueStatus.color}-900/20 rounded-full text-${dueStatus.color}-600 dark:text-${dueStatus.color}-400 
                          border border-${dueStatus.color}-200 dark:border-${dueStatus.color}-700`}>
              <Clock className="w-3 h-3" />
              {dueStatus.text}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const CategorySection = ({ category, cards, handleEditCard, handleDeleteCard, setCardToDelete  }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const stats = useMemo(() => ({
    total: cards.length,
    due: cards.filter(c => new Date(c.next_review_date) <= new Date()).length,
    mastered: cards.filter(c => c.box === 5).length
  }), [cards]);

  return (
    <div className="space-y-4">
      <motion.div 
        className="bg-white dark:bg-gray-800 p-4 rounded-xl shadow-sm border border-amber-100 dark:border-gray-700 cursor-pointer
                   hover:shadow-md transition-all duration-200"
        whileHover={{ y: -2 }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-amber-50 dark:bg-amber-900/20 rounded-lg">
              <FolderOpen className="w-5 h-5 text-amber-500 dark:text-amber-400" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-200">{category}</h3>
              <div className="flex items-center gap-4 mt-1">
                <span className="text-sm text-amber-600 dark:text-amber-400 flex items-center gap-1">
                  <Box className="w-3 h-3" />
                  {stats.total} cards
                </span>
                <span className="text-sm text-amber-600 dark:text-amber-400 flex items-center gap-1">
                  <Clock className="w-3 h-3" />
                  {stats.due} due
                </span>
                <span className="text-sm text-amber-600 dark:text-amber-400 flex items-center gap-1">
                  <Check className="w-3 h-3" />
                  {stats.mastered} mastered
                </span>
              </div>
            </div>
          </div>
          {isCollapsed ? 
            <ChevronDown className="w-5 h-5 text-amber-600 dark:text-amber-400" /> : 
            <ChevronUp className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          }
        </div>
      </motion.div>
      
      <AnimatePresence>
        {!isCollapsed && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="space-y-3 pl-4"
          >
            {cards.map(card => (
              <CardItem
                key={card.id}
                card={card}
                handleEditCard={handleEditCard}
                onDelete={handleDeleteCard}
                setCardToDelete={setCardToDelete}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FilterButton = ({ active, icon: Icon, label, onClick }) => (
  <Button
    onClick={onClick}
    className={`px-3 py-1.5 rounded-lg flex items-center gap-2 text-sm
               ${active 
                 ? 'bg-amber-100 dark:bg-amber-900/40 text-amber-800 dark:text-amber-200' 
                 : 'text-amber-600 dark:text-amber-400 hover:bg-amber-50 dark:hover:bg-gray-700'}`}
  >
    <Icon className="w-4 h-4" />
    {label}
  </Button>
);

export default function ManageCardsView({ 
  setView,
  handleEditCard,
  handleDeleteCard,
  onAddNew,
  onClose
}) {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all'); // 'all', 'due', 'mastered'
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [categories, setCategories] = useState(['General']);

  // Fetch both cards and categories when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch cards
        const cardsResponse = await fetch(`${API_URL}/flashcards`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include'
        });

        if (!cardsResponse.ok) {
          throw new Error('Failed to fetch cards');
        }

        // Fix: Properly await and parse the JSON response
        const cardsData = await cardsResponse.json();

        // Fetch categories
        const categoriesResponse = await fetch(`${API_URL}/categories`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include'
        });

        if (!categoriesResponse.ok) {
          throw new Error('Failed to fetch categories');
        }

        const categoriesData = await categoriesResponse.json();

        // Process categories
        const uniqueCategories = new Set(['General']);
        categoriesData.forEach(category => {
          if (typeof category === 'object') {
            uniqueCategories.add(category.name);
          } else {
            uniqueCategories.add(category);
          }
        });

        // Update state
        setCards(Array.isArray(cardsData) ? cardsData : []); // Ensure cards is always an array
        setCategories(Array.from(uniqueCategories));
        setError('');
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load cards and categories');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this runs once when component mounts

  // Handle delete with automatic refresh
  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await handleDeleteCard(cardToDelete);
      
      // Refresh cards after deletion
      const response = await fetch(`${API_URL}/flashcards`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to refresh cards');
      }

      const freshCards = await response.json();
      setCards(Array.isArray(freshCards) ? freshCards : []); // Ensure cards is always an array
      setCardToDelete(null);
    } catch (error) {
      console.error('Error deleting card:', error);
      setError('Failed to delete card');
    } finally {
      setDeleteLoading(false);
    }
  };

  // Memoized filtered and grouped cards
  const filteredAndGroupedCards = useMemo(() => {
    if (!Array.isArray(cards)) return {};
    
    return cards
      .filter(card => {
        const matchesSearch = card.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            card.answer.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesFilter = filter === 'all' ||
                            (filter === 'due' && new Date(card.next_review_date) <= new Date()) ||
                            (filter === 'mastered' && card.box === 5);
        return matchesSearch && matchesFilter;
      })
      .reduce((acc, card) => {
        const category = card.category || 'General';
        if (!acc[category]) acc[category] = [];
        acc[category].push(card);
        return acc;
      }, {});
  }, [cards, searchTerm, filter]);

  // Stats calculation
  const stats = useMemo(() => ({
    total: cards.length || 0,
    due: Array.isArray(cards) ? cards.filter(c => new Date(c.next_review_date) <= new Date()).length : 0,
    mastered: Array.isArray(cards) ? cards.filter(c => c.box === 5).length : 0
  }), [cards]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="flex flex-col items-center gap-4">
          <Loader className="w-8 h-8 animate-spin text-amber-500" />
          <p className="text-amber-600 dark:text-amber-400">Loading cards...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center space-y-4">
          <AlertCircle className="w-8 h-8 text-red-500 mx-auto" />
          <p className="text-red-500">{error}</p>
          <Button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-amber-500 text-white rounded-lg"
          >
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  const handleClose = () => {
    setView('dashboard');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="flex flex-col h-full dark:bg-gray-900">
      {/* Back to Dashboard Button - Visible on desktop only */}
      <div className="hidden md:block">
        <Button
          onClick={handleClose}
          className="px-4 py-2 bg-white dark:bg-gray-800 text-amber-600 dark:text-amber-400 
                   hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg border border-amber-100 
                   dark:border-amber-900/50 flex items-center gap-2 transition-colors"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Dashboard
        </Button>
      </div>
      {/* Header */}
      <div className="p-6 border-b border-amber-100 dark:border-gray-700">
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-2xl font-bold text-amber-800 dark:text-amber-200 flex items-center gap-2">
              <FolderOpen className="w-6 h-6 text-amber-600 dark:text-amber-400" />
              Manage Flashcards
            </h2>
            <p className="text-amber-600 dark:text-amber-400 mt-1">
              {stats.total} cards • {stats.due} due • {stats.mastered} mastered
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Button
              onClick={() => {setView('add'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
              className="px-4 py-2 bg-gradient-to-r from-amber-500 to-amber-600 dark:from-amber-600 dark:to-amber-700 
                       text-white rounded-lg flex items-center gap-2
                       hover:shadow-md transition-all duration-200"
            >
              <Plus className="w-4 h-4" />
              Create Card
            </Button>
            <Button
              onClick={() => {setView('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
              className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <X className="w-5 h-5 text-amber-600 dark:text-amber-400" />
            </Button>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-amber-500 dark:text-amber-400 w-5 h-5" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search cards by question or answer..."
              className="w-full pl-10 pr-4 py-2.5 border border-amber-200 dark:border-gray-600 
                       bg-white dark:bg-gray-800 text-amber-900 dark:text-amber-100 rounded-lg 
                       focus:outline-none focus:ring-2 focus:ring-amber-500
                       placeholder:text-amber-300 dark:placeholder:text-amber-500"
            />
          </div>
          <div className="flex items-center gap-2">
            <FilterButton
              active={filter === 'all'}
              icon={Filter}
              label="All"
              onClick={() => setFilter('all')}
            />
            <FilterButton
              active={filter === 'due'}
              icon={Calendar}
              label="Due"
              onClick={() => setFilter('due')}
            />
            <FilterButton
              active={filter === 'mastered'}
              icon={Check}
              label="Mastered"
              onClick={() => setFilter('mastered')}
            />
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="p-6 overflow-y-auto flex-1">
        <div className="space-y-6">
          {Object.entries(filteredAndGroupedCards).map(([category, categoryCards]) => (
            <CategorySection
              key={category}
              category={category}
              cards={categoryCards}
              handleEditCard={handleEditCard}
              handleDeleteCard={handleDeleteCard}
              setCardToDelete={setCardToDelete} 
            />
          ))}

          {Object.keys(filteredAndGroupedCards).length === 0 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center py-12 px-4"
            >
              <div className="bg-amber-50 dark:bg-amber-900/20 w-20 h-20 rounded-full flex items-center justify-center mx-auto mb-4">
                {searchTerm ? (
                  <Search className="w-8 h-8 text-amber-400" />
                ) : (
                  <AlertCircle className="w-8 h-8 text-amber-400" />
                )}
              </div>
              <h3 className="text-lg font-medium text-amber-800 dark:text-amber-200 mb-2">
                {searchTerm ? 'No matching cards found' : 'No cards yet'}
              </h3>
              <p className="text-amber-600 dark:text-amber-400 max-w-md mx-auto">
                {searchTerm ? (
                  'Try adjusting your search term or filter to find what youre looking for'
                ) : (
                  'Get started by creating your first flashcard using the button above'
                )}
              </p>
              {searchTerm && (
                <Button
                  onClick={() => setSearchTerm('')}
                  className="mt-4 px-4 py-2 bg-amber-50 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400 rounded-lg
                           hover:bg-amber-100 dark:hover:bg-amber-900/30 transition-colors flex items-center gap-2"
                >
                  <X className="w-4 h-4" />
                  Clear search
                </Button>
              )}
            </motion.div>
          )}
        </div>
      </div>

      {/* Quick Tips */}
      {cards.length > 0 && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-8 p-4 bg-amber-50 dark:bg-amber-900/20 rounded-xl border border-amber-100 dark:border-amber-800"
        >
          <div className="flex items-start gap-3">
            <div className="p-2 bg-amber-100 dark:bg-amber-900/40 rounded-lg shrink-0">
              <AlertTriangle className="w-4 h-4 text-amber-600 dark:text-amber-400" />
            </div>
            <div>
              <h4 className="text-sm font-medium text-amber-800 dark:text-amber-200">Quick Tips</h4>
              <ul className="mt-2 text-sm text-amber-600 dark:text-amber-400 space-y-1">
                <li className="flex items-center gap-2">
                  <span className="w-1 h-1 bg-amber-400 dark:bg-amber-500 rounded-full" />
                  Click on a card to view its full answer
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1 h-1 bg-amber-400 dark:bg-amber-500 rounded-full" />
                  Use filters to quickly find due or mastered cards
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1 h-1 bg-amber-400 dark:bg-amber-500 rounded-full" />
                  Search works across both questions and answers
                </li>
              </ul>
            </div>
          </div>
        </motion.div>
      )}
      <DeleteConfirmationDialog
        isOpen={!!cardToDelete}
        onClose={() => setCardToDelete(null)}
        onConfirm={handleDelete}
        title="Delete Flashcard"
        message="This action cannot be undone. Are you sure you want to delete this flashcard?"
        itemName={cardToDelete ? cards.find(c => c.id === cardToDelete)?.question : ''}
        isLoading={deleteLoading}
      />
    </div>
  );
}