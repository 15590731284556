import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Calendar, BarChart } from 'lucide-react';
import { Button } from './ui/Button';
import { getStats } from '../utils/leitnerSystem';
import { getDueCount } from '../utils/dueDate';

export const LeitnerDashboard = ({ cards, onStartStudy, setStudyMode, setView }) => {
  // Calculate stats using the new utility functions
  const stats = {
    totalCards: cards.length,
    dueToday: getDueCount(cards),
    boxCounts: cards.reduce((counts, card) => {
      counts[card.box] = (counts[card.box] || 0) + 1;
      return counts;
    }, {}),
  };

  const handleStartStudy = () => {
    setStudyMode('due');
    setView('study');
  };
  
  return (
    <div className="space-y-6">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-amber-800 dark:text-amber-400">Total Cards</p>
              <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.totalCards}</p>
            </div>
            <Brain className="w-8 h-8 text-amber-500 dark:text-amber-400" />
          </div>
        </div>
        
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-amber-800 dark:text-amber-400">Due Today</p>
              <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.dueToday}</p>
            </div>
            <Calendar className="w-8 h-8 text-amber-500 dark:text-amber-400" />
          </div>
        </div>
        
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-amber-800 dark:text-amber-400">Mastered</p>
              <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.boxCounts[5] || 0}</p>
            </div>
            <BarChart className="w-8 h-8 text-amber-500 dark:text-amber-400" />
          </div>
        </div>
      </div>

      {/* Box Distribution */}
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow p-6">
        <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-400 mb-4">Box Distribution</h3>
        <div className="space-y-3">
          {[1, 2, 3, 4, 5].map((box) => (
            <div key={box} className="flex items-center">
              <span className="w-20 text-sm text-gray-900 dark:text-white">Box {box}</span>
              <div className="flex-1 bg-amber-100 dark:bg-gray-700 rounded-full h-4 overflow-hidden">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${((stats.boxCounts[box] || 0) / stats.totalCards) * 100}%` }}
                  className="h-full bg-amber-500 dark:bg-amber-400"
                />
              </div>
              <span className="w-16 text-right text-sm text-gray-900 dark:text-white">
                {stats.boxCounts[box] || 0} cards
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Study Button */}
      {stats.dueToday > 0 && (
        <Button
        onClick={handleStartStudy}
        className="w-full py-3 bg-amber-500 dark:bg-amber-600 text-white text-lg font-semibold rounded-xl"
      >
        Start Study Session ({stats.dueToday} cards)
      </Button>
      )}
    </div>
  );
};
