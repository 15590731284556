import React, { useMemo } from 'react';
import { LeitnerStudySession } from '../components/LeitnerStudySession';
import { isCardDue } from '../utils/dueDate';

const StudyView = ({ 
  cards, 
  setCards,
  selectedCategory, 
  handleUpdateCard, 
  onEndSession,
  mode = 'category'
}) => {
  // Store the initial set of study cards
  const studyCards = useMemo(() => {
    const filteredCards = mode === 'due'
      ? cards.filter(isCardDue)
      : cards;

    return mode === 'due' 
      ? filteredCards 
      : filteredCards.filter(card => 
          selectedCategory === "All" ? true : card.category === selectedCategory
        );
  }, [cards, selectedCategory, mode]); // Only recompute when these dependencies change

  const handleOptimisticUpdate = async (updatedCard) => {
    try {
      // Update the card in the main cards array but don't affect studyCards
      setCards(prevCards => 
        prevCards.map(card => 
          card.id === updatedCard.id ? updatedCard : card
        )
      );

      await handleUpdateCard(updatedCard);
    } catch (error) {
      console.error('Error updating card:', error);
    }
  };

  if (studyCards.length === 0) {
    return (
      <div className="bg-white dark:bg-gray-700 rounded-xl shadow-lg p-6 text-center">
        <h2 className="text-xl font-semibold text-amber-800 dark:text-amber-200 mb-4">
          {mode === 'due' 
            ? "No cards due for review!" 
            : "No cards available in this category"}
        </h2>
        <p className="text-amber-600 dark:text-amber-400">
          {mode === 'due' 
            ? "Come back later when you have cards to review." 
            : "Try selecting a different category or create some new cards."}
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-700 rounded-xl shadow-lg p-6">
      <LeitnerStudySession
        cards={studyCards}  // Pass the original filtered cards
        setCards={setCards}
        handleUpdateCard={handleOptimisticUpdate}
        onEndSession={onEndSession}
      />
    </div>
  );
};

export default StudyView;