import React from 'react';
import { Brain, TrendingUp, Clock, Award, BookOpen, Calendar } from 'lucide-react';
import { Button } from './ui/Button';

const StatCard = ({ icon: Icon, title, value, description }) => (
  <div className="bg-white dark:bg-gray-800 p-4 rounded-xl shadow-sm border border-amber-100 dark:border-gray-700 hover:border-amber-200 dark:hover:border-gray-500 transition-all">
    <div className="flex items-center gap-3">
      <div className="p-2 bg-amber-50 dark:bg-gray-700 rounded-lg">
        <Icon className="w-6 h-6 text-amber-600 dark:text-amber-400" />
      </div>
      <div>
        <h3 className="text-sm font-medium text-amber-800 dark:text-amber-200">{title}</h3>
        <p className="text-2xl font-bold text-amber-900 dark:text-amber-100">{value}</p>
        <p className="text-sm text-amber-600 dark:text-amber-400">{description}</p>
      </div>
    </div>
  </div>
);

const SpacedRepetitionStats = ({ cards, onStartStudy }) => {
  const totalCards = cards.length;
  const masteredCards = cards.filter(card => card.box === 5).length;
  const masteryPercentage = totalCards > 0 
    ? Math.round((masteredCards / totalCards) * 100) 
    : 0;

  const dueToday = cards.filter(card => {
    const nextReview = new Date(card.next_review_date);
    const today = new Date();
    return nextReview <= today;
  }).length;

  const nextReviewDate = cards
    .map(card => new Date(card.next_review_date))
    .filter(date => date > new Date())
    .sort((a, b) => a - b)[0];

  const averageBox = totalCards > 0
    ? Math.round(cards.reduce((sum, card) => sum + card.box, 0) / totalCards * 10) / 10
    : 0;

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-amber-800 dark:text-amber-200">Learning Progress</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <StatCard
          icon={Clock}
          title="Due Today"
          value={dueToday}
          description={dueToday > 0 ? "Time for your daily review!" : "All caught up!"}
        />

        <StatCard
          icon={Calendar}
          title="Next Review"
          value={nextReviewDate ? nextReviewDate.toLocaleDateString() : "No upcoming reviews"}
          description="Keep up the consistent practice!"
        />

        <StatCard
          icon={Award}
          title="Mastery Progress"
          value={`${masteryPercentage}%`}
          description={`${masteredCards} cards mastered`}
        />

        <StatCard
          icon={Brain}
          title="Learning Status"
          value={averageBox.toFixed(1)}
          description="Average box level"
        />

        <StatCard
          icon={BookOpen}
          title="Collection Size"
          value={totalCards}
          description="Total flashcards"
        />

        <StatCard
          icon={TrendingUp}
          title="Active Learning"
          value={cards.filter(card => card.box < 5).length}
          description="Cards in progress"
        />
      </div>

      {dueToday > 0 && (
        <div className="mt-4 p-4 bg-amber-50 dark:bg-gray-700 rounded-lg border border-amber-200 dark:border-gray-600">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-amber-800 dark:text-amber-200">
              <Clock className="w-5 h-5" />
              <span className="font-medium">Time for Review!</span>
              <span>You have {dueToday} cards scheduled for today.</span>
            </div>
            <Button 
              onClick={onStartStudy}
              className="bg-amber-500 dark:bg-amber-600 text-white px-4 py-2 rounded-lg hover:bg-amber-600 dark:hover:bg-amber-500"
            >
              Start Review Session
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpacedRepetitionStats;
