import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Check, X, Maximize2, Minimize2, Trophy, Shuffle, Loader, Box } from 'lucide-react';
import { Button } from './ui/Button';
import { processCardAnswer } from '../utils/leitnerSystem';
import EnhancedImage from './EnhancedImage';

const CardContent = ({ content, drawing, image, isQuestion }) => {
  const getTextSizeClass = (text) => {
    if (!text) return 'text-lg sm:text-xl';
    if (text.length > 200) return 'text-sm sm:text-lg';
    if (text.length > 100) return 'text-base sm:text-xl';
    return 'text-lg sm:text-xl';
  };

  return (
    <div className="flex flex-col items-center w-full gap-4">
      {content && (
        <p className={`${getTextSizeClass(content)} text-amber-900 dark:text-amber-100 
                      leading-relaxed break-words max-w-full sm:max-w-md mx-auto text-center`}>
          {content}
        </p>
      )}

      <div className="flex flex-col gap-4 w-full">
        {drawing && (
          <EnhancedImage
            src={drawing}
            alt={`${isQuestion ? 'Question' : 'Answer'} drawing`}
            className="max-h-40 sm:max-h-48 w-auto mx-auto object-contain"
          />
        )}
        
        {image && (
          <EnhancedImage
            src={image}
            alt={`${isQuestion ? 'Question' : 'Answer'} image`}
            className="max-h-40 sm:max-h-48 w-auto mx-auto object-contain"
          />
        )}
      </div>
    </div>
  );
};

export const LeitnerStudySession = ({ 
  cards = [], 
  handleUpdateCard, 
  onEndSession 
}) => {
  const [originalCards, setOriginalCards] = useState([...cards].sort(() => Math.random() - 0.5)); // Fixed: added setter
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [sessionStats, setSessionStats] = useState({ correct: 0, incorrect: 0 });
  const [isAnswering, setIsAnswering] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showLabels, setShowLabels] = useState(true);

  const currentCard = originalCards[currentIndex];

  // Reset labels when card flips
  useEffect(() => {
    setShowLabels(true);
  }, [isFlipped]);

  const handleAnswer = async (isCorrect) => {
    if (isAnswering) return;
    setIsAnswering(true);
  
    try {
      const updatedCard = processCardAnswer(currentCard, isCorrect);
      
      setSessionStats(prev => ({
        correct: prev.correct + (isCorrect ? 1 : 0),
        incorrect: prev.incorrect + (isCorrect ? 0 : 1)
      }));
  
      // First, wait for card update
      await handleUpdateCard(updatedCard);
  
      // Then flip the card back
      setIsFlipped(false);
  
      // Wait for flip animation to complete before changing index
      setTimeout(() => {
        if (currentIndex < originalCards.length - 1) {
          setCurrentIndex(prev => prev + 1);
        } else {
          setShowSummary(true);
        }
      }, 300); // Half of the flip animation duration
  
    } catch (error) {
      console.error('Error updating card:', error);
    } finally {
      setIsAnswering(false);
    }
  };

  const shuffleCards = () => {
    const shuffled = [...cards].sort(() => Math.random() - 0.5);
    setOriginalCards(shuffled);
    setCurrentIndex(0);
    setSessionStats({ correct: 0, incorrect: 0 });
    setShowSummary(false);
    setIsFlipped(false);
  };

  if (originalCards.length === 0) {
    return (
      <div className="text-center py-8">
        <h2 className="text-2xl font-bold text-amber-800 dark:text-amber-200 mb-4">
          No cards available
        </h2>
        <p className="text-amber-700 dark:text-amber-400 mb-4">
          Add some flashcards to start studying!
        </p>
        <Button onClick={onEndSession} className="bg-amber-500 text-white">
          Back to Dashboard
        </Button>
      </div>
    );
  }

  if (showSummary) {
    const accuracy = sessionStats.correct + sessionStats.incorrect > 0
      ? Math.round((sessionStats.correct / (sessionStats.correct + sessionStats.incorrect)) * 100)
      : 0;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-center py-8"
      >
        <Trophy className="w-16 h-16 text-amber-500 dark:text-amber-400 mx-auto mb-4" />
        <h2 className="text-2xl font-bold text-amber-800 dark:text-amber-200 mb-4">
          Session Complete!
        </h2>
        <div className="space-y-2 mb-6">
          <p className="text-amber-700 dark:text-amber-400">
            Correct: {sessionStats.correct}
          </p>
          <p className="text-amber-700 dark:text-amber-400">
            Incorrect: {sessionStats.incorrect}
          </p>
          <p className="text-amber-700 dark:text-amber-400">
            Accuracy: {accuracy}%
          </p>
        </div>
        <div className="flex justify-center gap-4">
          <Button
            onClick={shuffleCards}
            className="px-6 py-2 bg-amber-500 dark:bg-amber-600 text-white flex items-center gap-2"
          >
            <Shuffle className="w-4 h-4" />
            Study Again
          </Button>
          <Button
            onClick={onEndSession}
            className="px-6 py-2 bg-gray-100 dark:bg-gray-700 text-amber-800 dark:text-amber-200"
          >
            Back to Dashboard
          </Button>
        </div>
      </motion.div>
    );
  }

  const fullscreenClass = isFullscreen ? 
    'fixed inset-0 z-50 bg-white dark:bg-gray-900' : 
    'relative w-full';

  return (
    <div className={fullscreenClass}>
      <div className="relative h-full flex flex-col">
        {/* Progress bar */}
        <div className="absolute top-0 left-0 right-0 h-1 bg-amber-100 dark:bg-gray-700">
          <div 
            className="h-full bg-amber-500 dark:bg-amber-600 transition-all duration-300"
            style={{ width: `${((currentIndex + 1) / originalCards.length) * 100}%` }}
          />
        </div>

        {/* Fullscreen toggle */}
        <button
          onClick={() => setIsFullscreen(!isFullscreen)}
          className="absolute top-4 right-4 z-10 p-2 bg-white dark:bg-gray-800 rounded-full shadow-lg
                   hover:bg-amber-50 dark:hover:bg-gray-700 transition-colors"
        >
          {isFullscreen ? (
            <Minimize2 className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          ) : (
            <Maximize2 className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          )}
        </button>

        {/* Card content */}
        {/* Card content - Updated with proper 3D transforms */}
        <div 
          className="flex-1 p-2 sm:p-6 flex items-center justify-center cursor-pointer"
          onClick={() => !isAnswering && setIsFlipped(!isFlipped)}
        >
          <div 
            className={`w-full max-w-xl bg-white dark:bg-gray-800 rounded-xl shadow-lg 
                       ${isFullscreen ? 'h-full' : 'min-h-[400px] sm:aspect-[3/2]'} relative`}
            style={{ perspective: '2000px' }}
          >
            {/* Card container with 3D transform */}
            <div 
              className="absolute inset-0 w-full h-full transition-transform duration-700"
              style={{ 
                transformStyle: 'preserve-3d',
                transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)'
              }}
            >
              {/* Front side */}
              <div
                className="absolute inset-0 w-full h-full p-4 sm:p-8 flex flex-col items-center justify-center bg-white dark:bg-gray-800 rounded-xl"
                style={{ 
                  backfaceVisibility: 'hidden',
                  WebkitBackfaceVisibility: 'hidden'
                }}
              >
                <AnimatePresence>
                  {showLabels && (
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2 }}
                      className="absolute top-2 sm:top-4 left-2 sm:left-4 px-2 py-0.5 sm:px-3 sm:py-1 
                               bg-amber-50 dark:bg-amber-900/40 rounded-full 
                               text-xs sm:text-sm text-amber-600 dark:text-amber-400"
                    >
                      Question
                    </motion.div>
                  )}
                </AnimatePresence>
                <CardContent
                  content={currentCard?.question}
                  drawing={currentCard?.front_drawing}
                  image={currentCard?.front_image}
                  isQuestion={true}
                />
              </div>

              {/* Back side */}
              <div
                className="absolute inset-0 w-full h-full p-4 sm:p-8 flex flex-col items-center justify-center bg-white dark:bg-gray-800 rounded-xl"
                style={{ 
                  transform: 'rotateY(180deg)',
                  backfaceVisibility: 'hidden',
                  WebkitBackfaceVisibility: 'hidden'
                }}
              >
                <AnimatePresence>
                  {showLabels && (
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2 }}
                      className="absolute top-2 sm:top-4 left-2 sm:left-4 px-2 py-0.5 sm:px-3 sm:py-1 
                               bg-amber-50 dark:bg-amber-900/40 rounded-full 
                               text-xs sm:text-sm text-amber-600 dark:text-amber-400"
                    >
                      Answer
                    </motion.div>
                  )}
                </AnimatePresence>
                <CardContent
                  content={currentCard?.answer}
                  drawing={currentCard?.back_drawing}
                  image={currentCard?.back_image}
                  isQuestion={false}
                />
              </div>
            </div>
          </div></div>

        {/* Answer buttons */}
        <div className="px-2 sm:px-4 pb-2 sm:pb-4 space-y-2 sm:space-y-4">
          {isFlipped && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="grid grid-cols-2 gap-2 sm:gap-4"
            >
              <button
                onClick={() => handleAnswer(false)}
                disabled={isAnswering}
                className="py-2 sm:py-3 px-2 sm:px-4 bg-red-500 hover:bg-red-600 dark:bg-red-600 
                         dark:hover:bg-red-700 text-white rounded-xl flex items-center 
                         justify-center gap-1 sm:gap-2 transition-colors disabled:opacity-50"
              >
                {isAnswering ? (
                  <Loader className="w-4 h-4 sm:w-5 sm:h-5 animate-spin" />
                ) : (
                  <X className="w-4 h-4 sm:w-5 sm:h-5" />
                )}
                <span className="text-sm sm:text-base font-medium">Incorrect</span>
              </button>

              <button
                onClick={() => handleAnswer(true)}
                disabled={isAnswering}
                className="py-2 sm:py-3 px-2 sm:px-4 bg-green-500 hover:bg-green-600 dark:bg-green-600 
                         dark:hover:bg-green-700 text-white rounded-xl flex items-center 
                         justify-center gap-1 sm:gap-2 transition-colors disabled:opacity-50"
              >
                {isAnswering ? (
                  <Loader className="w-4 h-4 sm:w-5 sm:h-5 animate-spin" />
                ) : (
                  <Check className="w-4 h-4 sm:w-5 sm:h-5" />
                )}
                <span className="text-sm sm:text-base font-medium">Correct</span>
              </button>
            </motion.div>
          )}

          {/* Stats - mobile optimized */}
          <div className="flex justify-between items-center text-[10px] sm:text-sm 
                       text-amber-600 dark:text-amber-400 px-1">
          <div className="flex items-center gap-1 sm:gap-2">
            <Box className="w-3 h-3 sm:w-4 sm:h-4" />
            <span>Box {currentCard?.box}</span>
          </div>
          <div>
            {currentIndex + 1}/{originalCards.length}
          </div>
          <div className="whitespace-nowrap">
            {sessionStats.correct}✓ {sessionStats.incorrect}✗
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};