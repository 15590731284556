import React from 'react';
import { Github, Linkedin, Mail, Heart, TableOfContents, MessageCircle } from 'lucide-react';

const Footer = ({ setView, user = null }) => {
  const handleFeedbackClick = () => {
    if (!user) {
      setView('auth');
    } else {
      setView('feedback');
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNavigation = (destination) => {
    setView(destination);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-gradient-to-b from-white to-amber-50 dark:from-gray-800 dark:to-gray-900 border-t border-amber-100 dark:border-amber-900/30 mt-auto">
      <div className="max-w-6xl mx-auto px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-200">RecallPeak</h3>
            <p className="text-amber-600 dark:text-amber-400">Supercharge your learning with AI-powered spaced repetition.</p>
          </div>
          
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-200">Resources</h3>
            <div className="flex flex-col space-y-2">
              <button 
                onClick={() => handleNavigation('faq')}
                className="flex items-center gap-2 text-amber-600 dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 transition-colors"
              >
                <TableOfContents className="w-4 h-4" />
                <span>FAQ</span>
              </button>
              {/* Feedback button with conditional behavior */}
              <button 
                onClick={handleFeedbackClick}
                className="flex items-center gap-2 text-amber-600 dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 transition-colors"
              >
                <MessageCircle className="w-4 h-4" />
                <span>Feedback{!user && ' (Sign in required)'}</span>
              </button>
            </div>
          </div>
          
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-200">Connect</h3>
            <div className="flex space-x-4">
              <a 
                href="https://www.linkedin.com/in/ahmedoogle/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="p-2 bg-white dark:bg-gray-700 rounded-lg shadow-sm hover:shadow-md transition-all hover:bg-amber-50 dark:hover:bg-gray-600"
              >
                <Linkedin className="w-5 h-5 text-amber-600 dark:text-amber-400" />
              </a>
              <a 
                href="https://github.com/gogosholo" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="p-2 bg-white dark:bg-gray-700 rounded-lg shadow-sm hover:shadow-md transition-all hover:bg-amber-50 dark:hover:bg-gray-600"
              >
                <Github className="w-5 h-5 text-amber-600 dark:text-amber-400" />
              </a>
              <a 
                href="mailto:ahmedoogle10@gmail.com" 
                className="p-2 bg-white dark:bg-gray-700 rounded-lg shadow-sm hover:shadow-md transition-all hover:bg-amber-50 dark:hover:bg-gray-600"
              >
                <Mail className="w-5 h-5 text-amber-600 dark:text-amber-400" />
              </a>
            </div>
            <p className="text-sm text-amber-600 dark:text-amber-400">
              Get in touch with us on social media or via email
            </p>
          </div>
        </div>

        <div className="border-t border-amber-100 dark:border-amber-900/30 pt-8 flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex items-center gap-2 text-amber-600 dark:text-amber-400">
            <span>Made with</span>
            <Heart className="w-4 h-4 text-amber-500 dark:text-amber-400 fill-amber-500 dark:fill-amber-400" />
            <span>for learners worldwide</span>
          </div>
          
          <div className="flex items-center gap-6 text-sm">
            <button 
              onClick={() => handleNavigation('terms')}
              className="text-amber-600 dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 transition-colors"
            >
              Terms
            </button>
            <button 
              onClick={() => handleNavigation('privacy')}
              className="text-amber-600 dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 transition-colors"
            >
              Privacy
            </button>
            <span className="text-amber-600 dark:text-amber-400">© {new Date().getFullYear()} RecallPeak</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;