import React, { useState } from 'react';
import { 
  MessageCircle, 
  Star, 
  ArrowLeft,
  Send,
  ThumbsUp,
  Bug,
  Lightbulb,
  AlertCircle
} from 'lucide-react';
import { Button } from '../components/ui/Button';
import { API_URL } from '../config/api';

const FeedbackView = ({ user, setView }) => {
  const [feedbackType, setFeedbackType] = useState('general');
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const response = await fetch(`${API_URL}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify({
          type: feedbackType,
          rating,
          message
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit feedback');
      }
  
      setSuccess(true);
      setMessage('');
      setRating(0);
      setFeedbackType('general');
    } catch (error) {
      setError('Failed to submit feedback. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const feedbackTypes = [
    { id: 'general', label: 'General Feedback', icon: MessageCircle },
    { id: 'bug', label: 'Report a Bug', icon: Bug },
    { id: 'feature', label: 'Feature Request', icon: Lightbulb },
  ];

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-8">
        <Button
          onClick={() => {setView('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
          variant="ghost"
          className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
        >
          <ArrowLeft className="w-5 h-5 text-amber-600 dark:text-amber-400" />
        </Button>
        <h1 className="text-2xl font-bold text-amber-800 dark:text-amber-200">Feedback</h1>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border border-amber-100 dark:border-gray-700">
        {success ? (
          <div className="text-center py-8">
            <div className="w-16 h-16 bg-green-100 dark:bg-green-900 rounded-full flex items-center justify-center mx-auto mb-4">
              <ThumbsUp className="w-8 h-8 text-green-600 dark:text-green-400" />
            </div>
            <h2 className="text-xl font-semibold text-green-600 dark:text-green-400 mb-2">
              Thank You for Your Feedback!
            </h2>
            <p className="text-amber-600 dark:text-amber-400 mb-6">
              Your input helps us improve RecallPeak.
            </p>
            <Button
              onClick={() => setSuccess(false)}
              className="px-6 py-2 bg-amber-500 text-white hover:bg-amber-600 dark:bg-amber-600 dark:hover:bg-amber-700"
            >
              Send Another Feedback
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Feedback Type Selection */}
            <div className="space-y-3">
              <label className="block text-sm font-medium text-amber-800 dark:text-amber-200">
                Feedback Type
              </label>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {feedbackTypes.map(({ id, label, icon: Icon }) => (
                  <button
                    key={id}
                    type="button"
                    onClick={() => setFeedbackType(id)}
                    className={`p-4 rounded-lg border ${
                      feedbackType === id
                        ? 'border-amber-500 bg-amber-50 dark:bg-amber-900/30'
                        : 'border-amber-200 dark:border-gray-600 hover:border-amber-300 dark:hover:border-gray-500'
                    } flex flex-col items-center gap-2 transition-colors`}
                  >
                    <Icon className={`w-6 h-6 ${
                      feedbackType === id
                        ? 'text-amber-600 dark:text-amber-400'
                        : 'text-amber-500 dark:text-amber-500'
                    }`} />
                    <span className={feedbackType === id
                      ? 'text-amber-800 dark:text-amber-200'
                      : 'text-amber-600 dark:text-amber-400'
                    }>{label}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* Rating */}
            <div className="space-y-3">
              <label className="block text-sm font-medium text-amber-800 dark:text-amber-200">
                Rating
              </label>
              <div className="flex gap-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    onClick={() => setRating(star)}
                    className="p-2"
                  >
                    <Star
                      className={`w-8 h-8 ${
                        star <= rating
                          ? 'fill-amber-400 text-amber-400'
                          : 'text-amber-200 dark:text-gray-600'
                      }`}
                    />
                  </button>
                ))}
              </div>
            </div>

            {/* Message */}
            <div className="space-y-3">
              <label className="block text-sm font-medium text-amber-800 dark:text-amber-200">
                Message
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Share your thoughts with us..."
                className="w-full px-4 py-3 border border-amber-200 dark:border-gray-600 rounded-xl 
                         focus:outline-none focus:ring-2 focus:ring-amber-500 min-h-[150px] resize-none
                         bg-white dark:bg-gray-900 text-amber-800 dark:text-amber-200
                         placeholder:text-amber-400 dark:placeholder:text-amber-600"
                required
              />
            </div>

            {error && (
              <div className="bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 px-4 py-3 rounded-lg flex items-center gap-2">
                <AlertCircle className="w-5 h-5" />
                {error}
              </div>
            )}

            {/* Submit Button */}
            <Button
              type="submit"
              disabled={loading || !message.trim()}
              className="w-full py-3 bg-gradient-to-r from-amber-500 to-amber-600 
                       dark:from-amber-600 dark:to-amber-700
                       text-white hover:from-amber-600 hover:to-amber-700 
                       dark:hover:from-amber-700 dark:hover:to-amber-800
                       rounded-xl shadow-sm hover:shadow-md transition-all
                       disabled:opacity-50 disabled:cursor-not-allowed
                       flex items-center justify-center gap-2"
            >
              {loading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              ) : (
                <>
                  <Send className="w-5 h-5" />
                  Submit Feedback
                </>
              )}
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default FeedbackView;