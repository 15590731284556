import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Key, ArrowUp, Clock, ChevronRight, Boxes, Play, Trophy, BarChart } from 'lucide-react';
import { Button } from './ui/Button';

const LeitnerBox = ({ level, days, cardCount = "0" }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="relative bg-white dark:bg-gray-800 rounded-xl p-4 shadow-lg border border-amber-100 dark:border-gray-700"
  >
    <div className="absolute top-2 right-2 text-amber-400 dark:text-amber-300 text-sm font-medium">
      {cardCount} cards
    </div>
    <h4 className="text-lg font-bold text-amber-800 dark:text-amber-200">Box {level}</h4>
    <p className="text-amber-600 dark:text-amber-400 text-sm">Review every {days} days</p>
  </motion.div>
);

const FeatureCard = ({ icon: Icon, title, children }) => (
  <motion.div
    whileHover={{ y: -5 }}
    className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-lg border border-amber-100 dark:border-gray-700"
  >
    <div className="p-3 bg-amber-50 dark:bg-amber-900/30 rounded-xl inline-block mb-3">
      <Icon className="w-6 h-6 text-amber-600 dark:text-amber-400" />
    </div>
    <h3 className="text-xl font-bold text-amber-800 dark:text-amber-200 mb-2">{title}</h3>
    <p className="text-amber-600 dark:text-amber-400 leading-relaxed">{children}</p>
  </motion.div>
);

const ShortcutButton = ({ keys, description }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="flex items-center justify-between bg-white dark:bg-gray-800 p-4 rounded-xl shadow-sm border border-amber-100 dark:border-gray-700"
  >
    <span className="font-medium text-amber-700 dark:text-amber-300">{description}</span>
    <div className="flex gap-2">
      {keys.map((key, index) => (
        <kbd key={index} className="px-3 py-2 bg-amber-50 dark:bg-amber-900/30 rounded-lg text-amber-700 dark:text-amber-300 font-semibold">
          {key}
        </kbd>
      ))}
    </div>
  </motion.div>
);

const GettingStarted = ({ onStartLearning, hasExistingCards, isLoggedIn }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-6xl mx-auto p-8"
    >
      <div className="text-center mb-12">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <h1 className="text-5xl font-bold text-amber-800 dark:text-amber-200 mb-6">
            Master Any Subject with RecallPeak
          </h1>
          <p className="text-xl text-amber-600 dark:text-amber-400 max-w-2xl mx-auto mb-8">
            Scientifically proven spaced repetition for optimal learning
          </p>
        </motion.div>
      </div>

      <div className="grid md:grid-cols-3 gap-6 mb-12">
        <FeatureCard icon={Brain} title="Optimized Learning">
          Progress through boxes with increasing intervals, reinforcing knowledge efficiently.
        </FeatureCard>
        <FeatureCard icon={Trophy} title="Track Mastery">
          Watch cards advance through boxes as you master the content.
        </FeatureCard>
        <FeatureCard icon={BarChart} title="Smart Analytics">
          Monitor your progress and identify areas needing focus.
        </FeatureCard>
      </div>

      <div className="text-center mb-16">
        <Button
          onClick={onStartLearning}
          className="group px-8 py-4 bg-amber-500 dark:bg-amber-600 text-white text-xl font-semibold rounded-full hover:bg-amber-600 dark:hover:bg-amber-700 transition-all shadow-xl hover:shadow-2xl"
        >
          {isLoggedIn ? (
            hasExistingCards ? "Continue Learning" : "Create Your First Card"
          ) : (
            "Start Learning"
          )}
          <ChevronRight className="w-6 h-6 ml-2 inline-block group-hover:translate-x-1 transition-transform" />
        </Button>
      </div>

      <div className="mb-16">
        <h2 className="text-2xl font-bold text-amber-800 dark:text-amber-200 mb-6 text-center">How the Leitner System Works</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
          <LeitnerBox level="1" days="1" cardCount="12" />
          <LeitnerBox level="2" days="2" cardCount="8" />
          <LeitnerBox level="3" days="4" cardCount="6" />
          <LeitnerBox level="4" days="8" cardCount="4" />
          <LeitnerBox level="5" days="16" cardCount="2" />
        </div>
        <div className="bg-amber-50 dark:bg-amber-900/30 rounded-xl p-6">
          <div className="flex items-center gap-3 mb-4">
            <ArrowUp className="w-5 h-5 text-amber-600 dark:text-amber-400" />
            <p className="text-amber-800 dark:text-amber-200 font-medium">
              Answer correctly to advance cards to the next box
            </p>
          </div>
          <div className="flex items-center gap-3">
            <Boxes className="w-5 h-5 text-amber-600 dark:text-amber-400" />
            <p className="text-amber-800 dark:text-amber-200 font-medium">
              Wrong answers return cards to Box 1 for more frequent review
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-br from-amber-50 to-white dark:from-amber-900/30 dark:to-gray-800 rounded-3xl p-8 shadow-lg">
        <div className="flex items-center gap-3 mb-6">
          <Key className="w-6 h-6 text-amber-600 dark:text-amber-400" />
          <h2 className="text-2xl font-bold text-amber-800 dark:text-amber-200">Quick Controls</h2>
        </div>
        <div className="grid md:grid-cols-2 gap-4">
          <ShortcutButton keys={['Space']} description="Flip card" />
          <ShortcutButton keys={['→']} description="Next card" />
          <ShortcutButton keys={['←']} description="Previous card" />
          <ShortcutButton keys={['1']} description="Mark as incorrect" />
          <ShortcutButton keys={['2']} description="Mark as correct" />
          <ShortcutButton keys={['Esc']} description="Exit session" />
        </div>
      </div>
    </motion.div>
  );
};

export default GettingStarted;