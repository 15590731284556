import React from 'react';
import { Circle } from 'lucide-react';

const AICardCounter = ({ cardsGenerated = 0, maxCards = 30 }) => {
  const remaining = Math.max(0, maxCards - cardsGenerated); // Ensure it doesn't go negative
  const percentage = Math.min(100, (cardsGenerated / maxCards) * 100); // Ensure it doesn't exceed 100%

  return (
    <div className="p-4 bg-amber-50 dark:bg-amber-900/20 rounded-lg border border-amber-100 dark:border-amber-800">
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm font-medium text-amber-800 dark:text-amber-200">
          Daily AI Generation Limit
        </span>
        <span className="text-sm text-amber-600 dark:text-amber-400">
          {cardsGenerated} / {maxCards} cards ({remaining} remaining)
        </span>
      </div>
      
      <div className="relative h-2 bg-amber-200 dark:bg-amber-800 rounded-full overflow-hidden">
        <div 
          className="absolute top-0 left-0 h-full bg-amber-500 dark:bg-amber-600 transition-all duration-300"
          style={{ width: `${percentage}%` }}
        />
      </div>

      <div className="mt-2 flex items-center gap-2">
        <Circle className="w-2 h-2 fill-amber-500 text-amber-500" />
        <span className="text-xs text-amber-600 dark:text-amber-400">
          Daily limit resets at midnight
        </span>
      </div>
    </div>
  );
};

export default AICardCounter;