// src/utils/leitnerSystem.js

// Box review intervals in days
export const BOX_INTERVALS = {
    1: 1,  // every day
    2: 2,  // every 2 days
    3: 4,  // every 4 days
    4: 8,  // every 8 days
    5: 16  // every 16 days
  };
  
  // Calculate next review date based on box number
  export const calculateNextReview = (boxNumber) => {
    const today = new Date();
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + BOX_INTERVALS[boxNumber]);
    return nextDate.toISOString();
  };
  
  // Check if a card is due for review today
  export const isCardDueToday = (card) => {
    if (!card.nextReviewDate) return true;
    
    const today = new Date();
    const nextReview = new Date(card.nextReviewDate);
    
    today.setHours(0, 0, 0, 0);
    nextReview.setHours(0, 0, 0, 0);
    
    return nextReview <= today;
  };
  
  // Get cards due for review today
  export const getDueCards = (cards) => {
    return cards.filter(isCardDueToday);
  };
  
  // Update card box and review dates after answer
  export const processCardAnswer = (card, isCorrect) => {
    const newBox = isCorrect ? Math.min(card.box + 1, 5) : 1;
    const nextReviewDate = new Date();
    nextReviewDate.setDate(nextReviewDate.getDate() + BOX_INTERVALS[newBox]);
  
    return {
      ...card,
      box: newBox,
      next_review_date: nextReviewDate.toISOString(),
      last_review_date: new Date().toISOString()
    };
  };
  
  // Get statistics for cards
  export const getStats = (cards) => {
    const boxCounts = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
    const dueToday = getDueCards(cards).length;
    
    cards.forEach(card => {
      boxCounts[card.box] = (boxCounts[card.box] || 0) + 1;
    });
    
    return {
      totalCards: cards.length,
      dueToday,
      boxCounts,
      mastered: boxCounts[5] || 0
    };
  };