// src/App.js
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ArrowLeft, Settings, Cog } from 'lucide-react'; // Added Settings to the import
import { SignIn } from './components/auth/SignIn';
import { SignUp } from './components/auth/SignUp';
import { Button } from './components/ui/Button';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import GettingStarted from './components/GettingStarted';
import KeyboardShortcuts from './components/KeyboardShortcuts';
import DashboardView from './views/DashboardView';
import StudyView from './views/StudyView';
import CardFormView from './views/CardFormView';
import { useAuth } from './hooks/useAuth';
import { useCards } from './hooks/useCards';
import LoadingSpinner from './components/common/LoadingSpinner';
import logo from './images/icon.png';  // Correct import path
import CategoryManager from './components/CategoryManager';
import ManageCardsView from './views/ManageCardsView';
import ProgressView from './views/ProgressView';
import CategoryManagerView from './views/CategoryManagerView';
import SettingsView from './views/SettingsView';
import FeedbackView from './views/FeedbackView';
import AdminView from './views/AdminView';
import FAQView from './views/FAQView';
import TermsView from './views/TermsView';
import PrivacyView from './views/PrivacyView';
import { API_URL } from './config/api';
import { Toaster } from 'react-hot-toast';

function App() {
  // State
  const [isAdmin, setIsAdmin] = useState(false);
  const [view, setView] = useState('welcome');
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [showCategoryManager, setShowCategoryManager] = useState(false);
  const [showFlashcardManager, setShowFlashcardManager] = useState(false);
  const [cardBeingEdited, setCardBeingEdited] = useState(null);
  const [studyMode, setStudyMode] = useState('category');

  // Custom hooks - keep these at the top
  const { 
    user, 
    loading, 
    authView, 
    setAuthView, 
    handleSignIn, 
    handleSignOut 
  } = useAuth();
  
  const { 
    cards, 
    setCards, 
    categories,
    selectedCategory,
    setSelectedCategory,
    handleAddCard, 
    handleUpdateCard,
    handleEditCategory,
    handleDeleteCard,
    handleDeleteCategory,
    handleAddCategory,
    handleEditCard
  } = useCards(user);

   // Check admin status when user changes
   useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user) {
        setIsAdmin(false);
        return;
      }
  
      try {
        console.log('Checking admin status for user:', user.id); // Debug log
        const response = await fetch(`${API_URL}/auth/check-admin`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include'
        });
        const data = await response.json();
        console.log('Admin check response:', data); // Debug log
        setIsAdmin(data.isAdmin);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
    };
  
    checkAdminStatus();
  }, [user]);

  // Effect for handling authenticated user redirects
  useEffect(() => {
    if (user && (view === 'welcome' || view === 'auth')) {
      setView('dashboard');
    }
  }, [user, view]);

  // Handler for toggling category manager
  const toggleCategoryManager = () => {
    setShowCategoryManager(!showCategoryManager);
  };

  const checkAdminStatus = async () => {
    try {
      const response = await fetch(`${API_URL}/auth/check-admin`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });
      const data = await response.json();
      setIsAdmin(data.isAdmin);
    } catch (error) {
      console.error('Error checking admin status:', error);
    }
  };

  const handleViewChange = (newView) => {
    setView(newView);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSignOutClick = async () => {
    await handleSignOut();
    setView('welcome');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // Render loading state
  if (loading) {
    return <LoadingSpinner />;
  }
  // Main render
  return (
    <div className="min-h-screen bg-gradient-to-br from-amber-100 to-orange-200 dark:from-gray-900 dark:to-gray-800">
      <Toaster position="top-center" />
  
      <Navbar 
        user={user}
        setView={setView}
        setAuthView={setAuthView}
        handleSignOut={handleSignOutClick}
        setShowShortcuts={setShowShortcuts}
        setShowFlashcardManager={setShowFlashcardManager}
        setShowCategoryManager={setShowCategoryManager}
        logo={logo}
      />
  
      <div className="max-w-6xl mx-auto p-4 dark:bg-gray-900">
        <AnimatePresence mode="sync">
          {/* Welcome View */}
          {!user && view === 'welcome' && (
            <GettingStarted 
              onStartLearning={() => {
                setAuthView('signin');
                setView('auth');
              }}
              hasExistingCards={cards.length > 0}
              isLoggedIn={!!user}
            />
          )}
  
          {/* Auth View */}
          {!user && view === 'auth' && (
            <div className="flex items-center justify-center py-8">
              <AnimatePresence mode="wait">
                {authView === 'signin' ? (
                  <SignIn
                    onSignIn={(userData, token) => {
                      handleSignIn(userData, token);
                      setView('dashboard');
                    }}
                    onSwitchToSignUp={() => setAuthView('signup')}
                  />
                ) : (
                  <SignUp
                    onSignUp={(userData, token) => {
                      handleSignIn(userData, token);
                      setView('dashboard');
                    }}
                    onSwitchToSignIn={() => setAuthView('signin')}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
  
          {/* Public Pages */}
          {view === 'faq' && (
            <FAQView setView={setView} />
          )}
  
          {view === 'terms' && (
            <TermsView setView={setView} />
          )}
  
          {view === 'privacy' && (
            <PrivacyView setView={setView} />
          )}
  
          {/* Protected Routes */}
          {user && (
            <>
              {view === 'dashboard' && (
                <DashboardView 
                  cards={cards}
                  setCards={setCards}
                  setView={setView}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  categories={categories}
                  isAdmin={isAdmin}
                  setStudyMode={setStudyMode}
                />
              )}
  
              {view === 'feedback' && (
                <FeedbackView 
                  user={user}
                  setView={setView}
                />
              )}
  
              {view === 'settings' && (
                <SettingsView 
                  user={user}
                  setView={setView}
                />
              )}
  
              {view === 'progress' && (
                <ProgressView cards={cards} isPremium={true} />
              )}
  
              {view === 'study' && (
                <StudyView 
                  cards={cards}
                  setCards={setCards}
                  selectedCategory={selectedCategory}
                  handleUpdateCard={handleUpdateCard}
                  onEndSession={() => {
                    setView('dashboard');
                    setStudyMode('category');
                  }}
                  mode={studyMode} 
                />
              )}
  
              {(view === 'add' || view === 'edit') && (
                <CardFormView 
                  mode={view}
                  initialCard={cardBeingEdited}
                  handleAddCard={handleAddCard}
                  handleUpdateCard={handleUpdateCard}
                  handleAddCategory={handleAddCategory}
                  setView={setView}
                  categories={categories}
                />
              )}
  
              {view === 'manage-cards' && (
                <ManageCardsView 
                  cards={cards}
                  categories={categories}
                  setView={setView}
                  handleEditCard={(card) => {
                    setCardBeingEdited(card);
                    setView('edit');
                  }}
                  handleDeleteCard={handleDeleteCard}
                />
              )}

              {view === 'manage-categories' && (
                    <CategoryManagerView 
                      categories={categories}
                      cards={cards}
                      onEditCategory={handleEditCategory}
                      onDeleteCategory={handleDeleteCategory}
                      setView={setView}
                    />
                  )}
  
              {isAdmin && view === 'admin' && (
                <AdminView setView={setView} />
              )}
            </>
          )}
        </AnimatePresence>
      </div>
  
      {showShortcuts && (
        <KeyboardShortcuts onClose={() => setShowShortcuts(false)} />
      )}
      
      <Footer setView={setView} user={user} />
    </div>
  );
}

export default App;