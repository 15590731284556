// src/components/KeyboardShortcuts.js
import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import { Button } from './ui/Button';

const KeyboardShortcuts = ({ onClose }) => {
  const shortcuts = [
    { keys: ['Space'], action: 'Flip card' },
    { keys: ['→'], action: 'Next card' }, 
    { keys: ['←'], action: 'Previous card' },
    { keys: ['1'], action: 'Mark incorrect' },
    { keys: ['2'], action: 'Mark correct' },
    { keys: ['Esc'], action: 'Exit session' }
  ];

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-amber-800">Keyboard Shortcuts</h2>
          <Button onClick={onClose} className="text-amber-800 hover:text-amber-600">
            <X className="w-5 h-5" />
          </Button>
        </div>
        
        <div className="space-y-3">
          {shortcuts.map(({ keys, action }) => (
            <div key={action} className="flex justify-between items-center">
              <span className="text-amber-700">{action}</span>
              <div className="flex gap-2">
                {keys.map(key => (
                  <kbd key={key} className="px-2 py-1 bg-amber-50 rounded border border-amber-200">
                    {key}
                  </kbd>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyboardShortcuts;