import React from 'react';
import { ArrowLeft, Scale } from 'lucide-react';
import { Button } from '../components/ui/Button';

const TermsSection = ({ title, children }) => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold text-amber-800 dark:text-amber-200 mb-4">{title}</h2>
    <div className="space-y-4 text-amber-600 dark:text-amber-400">
      {children}
    </div>
  </div>
);

const TermsView = ({ setView }) => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-8">
        <Button
          onClick={() => {setView('dashboard'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
          variant="ghost"
          className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
        >
          <ArrowLeft className="w-5 h-5 text-amber-600 dark:text-amber-400" />
        </Button>
        <div>
          <h1 className="text-2xl font-bold text-amber-800 dark:text-amber-200 flex items-center gap-2">
            <Scale className="w-7 h-7" />
            Terms of Service
          </h1>
          <p className="text-amber-600 dark:text-amber-400 mt-1">
            Last updated: December 9, 2024
          </p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8 space-y-8">
        <TermsSection title="1. Acceptance of Terms">
          <p>
            By accessing and using RecallPeak, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree with these terms, please do not use our service.
          </p>
        </TermsSection>

        <TermsSection title="2. Service Description">
          <p>
            RecallPeak is a digital flashcard and learning platform that provides spaced repetition learning tools, AI-assisted content generation, and study progress tracking.
          </p>
          <p className="mt-2">
            We reserve the right to modify, suspend, or discontinue any aspect of the service at any time, with or without notice.
          </p>
        </TermsSection>

        <TermsSection title="3. User Accounts">
          <p>
            You must provide accurate and complete information when creating an account. You are responsible for:
          </p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Maintaining the confidentiality of your account credentials</li>
            <li>All activities that occur under your account</li>
            <li>Notifying us immediately of any unauthorized use</li>
          </ul>
        </TermsSection>

        <TermsSection title="4. User Content">
          <p>
            Users retain ownership of content they create on RecallPeak. By uploading content, you grant us a non-exclusive license to use, store, and display that content for the purpose of providing our service.
          </p>
          <p className="mt-2">
            You agree not to upload content that:
          </p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Infringes on intellectual property rights</li>
            <li>Contains malicious code or malware</li>
            <li>Violates any applicable laws or regulations</li>
            <li>Contains inappropriate or harmful material</li>
          </ul>
        </TermsSection>

        <TermsSection title="5. AI-Generated Content">
          <p>
            Our AI features are provided "as is" and we make no guarantees about the accuracy of AI-generated content. Users should review and verify all AI-generated flashcards before use.
          </p>
        </TermsSection>

        <TermsSection title="6. Subscription and Payments">
          <p>
            Free accounts and premium subscription details are subject to change. Refunds are handled according to our refund policy and applicable laws.
          </p>
        </TermsSection>

        <TermsSection title="7. Termination">
          <p>
            We reserve the right to suspend or terminate accounts that violate these terms or for any other reason at our discretion. Users may terminate their accounts at any time.
          </p>
        </TermsSection>

        <TermsSection title="8. Limitation of Liability">
          <p>
            RecallPeak is provided "as is" without warranties of any kind. We are not liable for any damages arising from your use of our service.
          </p>
        </TermsSection>

        <TermsSection title="9. Changes to Terms">
          <p>
            We may update these terms at any time. Continued use of RecallPeak after changes constitutes acceptance of the new terms.
          </p>
        </TermsSection>

        <TermsSection title="10. Contact">
          <p>
            For questions about these terms, please contact us at legal@recallpeak.com
          </p>
        </TermsSection>
      </div>
    </div>
  );
};

export default TermsView;