import React, { useState, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Maximize2, X, Plus, Minus, RefreshCw } from "lucide-react";
import debounce from "lodash.debounce";

const ImageViewer = ({ src, alt = "Image", onClose }) => {
  const [scale, setScale] = useState(1);
  const [translation, setTranslation] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startCoords, setStartCoords] = useState(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") onClose();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  const debouncedSetScale = useCallback(
    debounce((newScale) => setScale(newScale), 50),
    []
  );

  const increaseScale = (e) => {
    e.stopPropagation();
    debouncedSetScale((prev) => Math.min(prev + 0.5, 3));
  };

  const decreaseScale = (e) => {
    e.stopPropagation();
    debouncedSetScale((prev) => Math.max(prev - 0.5, 1));
  };

  const resetTransform = () => {
    setScale(1);
    setTranslation({ x: 0, y: 0 });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !startCoords) return;
    e.preventDefault();
    const deltaX = e.clientX - startCoords.x;
    const deltaY = e.clientY - startCoords.y;
    setTranslation((prev) => ({
      x: prev.x + deltaX,
      y: prev.y + deltaY,
    }));
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setStartCoords(null);
  };

  const handleTouchStart = (e) => {
    if (e.touches.length === 1) {
      setStartCoords({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    } else if (e.touches.length === 2) {
      const [touch1, touch2] = e.touches;
      const distance = Math.sqrt(
        (touch2.clientX - touch1.clientX) ** 2 +
          (touch2.clientY - touch1.clientY) ** 2
      );
      setStartCoords({ distance });
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 1 && startCoords?.x) {
      const deltaX = e.touches[0].clientX - startCoords.x;
      const deltaY = e.touches[0].clientY - startCoords.y;
      setTranslation((prev) => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY,
      }));
      setStartCoords({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    } else if (e.touches.length === 2 && startCoords?.distance) {
      const [touch1, touch2] = e.touches;
      const newDistance = Math.sqrt(
        (touch2.clientX - touch1.clientX) ** 2 +
          (touch2.clientY - touch1.clientY) ** 2
      );
      const scaleDelta = newDistance / startCoords.distance;
      setScale((prev) => Math.min(Math.max(prev * scaleDelta, 1), 3));
      setStartCoords({ distance: newDistance });
    }
  };

  const handleTouchEnd = () => {
    setStartCoords(null);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 bg-black/80 p-4"
      onClick={handleModalClick}
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className="relative w-full h-full max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-xl flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center bg-white dark:bg-gray-800 z-10">
          <div className="flex items-center gap-4">
            <button
              onClick={decreaseScale}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
              aria-label="Zoom Out"
            >
              <Minus className="w-5 h-5 text-amber-600 dark:text-amber-400" />
            </button>
            <span className="text-amber-600 dark:text-amber-400">
              {Math.round(scale * 100)}%
            </span>
            <button
              onClick={increaseScale}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
              aria-label="Zoom In"
            >
              <Plus className="w-5 h-5 text-amber-600 dark:text-amber-400" />
            </button>
            <button
              onClick={resetTransform}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
              aria-label="Reset Zoom and Position"
            >
              <RefreshCw className="w-5 h-5 text-amber-600 dark:text-amber-400" />
            </button>
          </div>
          <button
            onClick={handleModalClick}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            aria-label="Close"
          >
            <X className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          </button>
        </div>

        {/* Scrollable Container */}
        <div
          className="flex-1 overflow-hidden bg-gray-50 dark:bg-gray-900 relative"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className="relative w-full h-full flex items-center justify-center"
            style={{
              cursor: isDragging ? "grabbing" : "grab",
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
          >
            <div
              className="origin-center transition-transform duration-100"
              style={{
                transform: `scale(${scale}) translate(${translation.x}px, ${translation.y}px)`,
              }}
            >
              <img
                src={src}
                alt={alt}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const EnhancedImage = ({ src, alt = "Image", className = "" }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded(true);
  };

  return (
    <div className="relative group">
      <img
        src={src}
        alt={alt}
        className={`rounded-lg ${className}`}
      />
      <button
        onClick={handleExpandClick}
        className="absolute top-2 right-2 p-2 bg-white/90 dark:bg-gray-800/90 rounded-lg 
                 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity
                 hover:bg-amber-50 dark:hover:bg-gray-700"
        aria-label="Expand"
      >
        <Maximize2 className="w-4 h-4 text-amber-600 dark:text-amber-400" />
      </button>

      <AnimatePresence>
        {isExpanded && (
          <ImageViewer
            src={src}
            alt={alt}
            onClose={() => setIsExpanded(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default EnhancedImage;
