import React, { useState, useRef } from 'react';
import { 
  Wand2, 
  FileText, 
  Loader, 
  AlertCircle, 
  Upload,
  Target,
  Languages,
  Code,
  Brain,
  Sparkles,
  BookOpen,
  Plus,
  X
} from 'lucide-react';
import { Button } from './ui/Button';
import { motion, AnimatePresence } from 'framer-motion';
import AICardCounter from './AICardCounter';
import toast from 'react-hot-toast';
import { API_URL } from '../config/api';

const EXAMPLE_PROMPTS = [
  {
    icon: Languages,
    title: "Language Learning",
    examples: [
      "Help me learn basic Spanish greetings and phrases",
      "Teach me Japanese numbers 1-20 with pronunciation",
      "Create French vocabulary cards about food and dining"
    ]
  },
  {
    icon: Code,
    title: "Programming",
    examples: [
      "Explain key JavaScript array methods",
      "Python dictionary operations and examples",
      "Common React hooks and their usage"
    ]
  },
  {
    icon: Brain,
    title: "Academic",
    examples: [
      "Basic principles of photosynthesis",
      "Key events of World War II",
      "Fundamental algebra concepts"
    ]
  }
];

export default function AIGenerator({ 
  categories, 
  aiCardsGenerated, 
  onSuccess,
  onClose,
  handleAddCategory
}) {
  const [mode, setMode] = useState(null); // 'pdf' or 'prompt'
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [topic, setTopic] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [numCards, setNumCards] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState('General');
  const [focusArea, setFocusArea] = useState('');

  const [newCategory, setNewCategory] = useState('');
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const formContainerRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setError('');
    } else {
      setError('Please upload a PDF file');
      setFile(null);
      setFileName('');
    }
  };

  const handlePromptSubmit = async (e) => {
    e.preventDefault();
    if (!topic.trim()) {
      setError('Please enter a topic');
      return;
    }

    setLoading(true);
    setError('');

    try {
        const response = await fetch(`${API_URL}/flashcards/generate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            credentials: 'include',
            body: JSON.stringify({
              topic: topic.trim(),
              count: numCards,
              category: selectedCategory
            })
          });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message);

      onSuccess(data);
      toast.success(`Created ${data.cards.length} flashcards!`);
      setMode(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePDFSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError('Please select a PDF file');
      return;
    }

    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('numCards', numCards.toString());
    formData.append('category', selectedCategory);
    if (focusArea.trim()) {
      formData.append('focusArea', focusArea.trim());
    }

    try {
        const response = await fetch(`${API_URL}/flashcards/generate-from-pdf`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            credentials: 'include',
            body: formData
          });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message);

      onSuccess(data);
      toast.success(`Created ${data.cards.length} flashcards from PDF!`);
      setMode(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Selection screen when no mode is chosen
  if (!mode) {
    return (
      <div className="max-w-3xl mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-lg border border-amber-100 dark:border-amber-900/50">
          {/* Add header with close button */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-amber-800 dark:text-amber-200">
            Choose Your Learning Path
          </h2>
          <Button
            onClick={onClose}
            className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg transition-colors"
          >
            <X className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          </Button>
        </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Button
              onClick={() => setMode('prompt')}
              className="p-8 bg-gradient-to-br from-amber-50 to-amber-100 dark:from-amber-900/30 dark:to-amber-800/30
                       hover:from-amber-100 hover:to-amber-200 dark:hover:from-amber-900/40 dark:hover:to-amber-800/40
                       border border-amber-200 dark:border-amber-800 rounded-xl flex flex-col items-center gap-4
                       transition-all duration-200 group"
            >
              <div className="p-4 bg-white dark:bg-gray-900 rounded-full shadow-md group-hover:scale-110 transition-transform">
                <Wand2 className="w-8 h-8 text-amber-500 dark:text-amber-400" />
              </div>
              <div className="text-center">
                <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-200 mb-2">
                  AI Assistant
                </h3>
                <p className="text-amber-600 dark:text-amber-400 text-sm">
                  Let AI create custom flashcards from your topic or questions
                </p>
              </div>
            </Button>

            <Button
              onClick={() => setMode('pdf')}
              className="p-8 bg-gradient-to-br from-amber-50 to-amber-100 dark:from-amber-900/30 dark:to-amber-800/30
                       hover:from-amber-100 hover:to-amber-200 dark:hover:from-amber-900/40 dark:hover:to-amber-800/40
                       border border-amber-200 dark:border-amber-800 rounded-xl flex flex-col items-center gap-4
                       transition-all duration-200 group"
            >
              <div className="p-4 bg-white dark:bg-gray-900 rounded-full shadow-md group-hover:scale-110 transition-transform">
                <FileText className="w-8 h-8 text-amber-500 dark:text-amber-400" />
              </div>
              <div className="text-center">
                <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-200 mb-2">
                  PDF Extract
                </h3>
                <p className="text-amber-600 dark:text-amber-400 text-sm">
                  Generate flashcards from your PDF documents
                </p>
              </div>
            </Button>
          </div>

          <div className="mt-8">
            <AICardCounter cardsGenerated={aiCardsGenerated} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto">
    <div ref={formContainerRef} className="max-h-[90vh] overflow-y-auto">
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg border border-amber-100 dark:border-amber-900/50">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-2">
                {mode === 'prompt' ? (
                <Wand2 className="w-6 h-6 text-amber-500 dark:text-amber-400" />
                ) : (
                <FileText className="w-6 h-6 text-amber-500 dark:text-amber-400" />
                )}
                <h2 className="text-xl font-semibold text-amber-800 dark:text-amber-200">
                {mode === 'prompt' ? 'AI Assistant' : 'PDF Extraction'}
                </h2>
            </div>
            <div className="flex items-center">
                <Button
                onClick={() => setMode(null)}
                className="px-3 py-2 text-amber-600 dark:text-amber-400 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
                >
                Back
                </Button>
                <Button
                onClick={onClose}
                className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg ml-2"
                >
                <X className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                </Button>
            </div>
        </div>

        {/* Form */}
        <form onSubmit={mode === 'prompt' ? handlePromptSubmit : handlePDFSubmit} className="space-y-6">
          {mode === 'prompt' ? (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-amber-800 dark:text-amber-200">
                What would you like to learn about?
              </label>
              <textarea
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                placeholder="Describe what you want to learn..."
                className="w-full px-4 py-3 border border-amber-200 dark:border-amber-900/50 rounded-xl 
                         bg-white dark:bg-gray-900 text-amber-900 dark:text-amber-100
                         focus:ring-2 focus:ring-amber-500 focus:outline-none
                         placeholder:text-amber-400 min-h-[100px]"
              />
            </div>
          ) : (
            <>
              <div className="relative">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".pdf"
                  className="hidden"
                  id="pdf-upload"
                />
                <label
                  htmlFor="pdf-upload"
                  className={`flex flex-col items-center justify-center w-full h-32 border-2 border-dashed 
                           rounded-xl cursor-pointer transition-colors
                           ${file ? 'border-amber-400 bg-amber-50' : 'border-amber-200 hover:border-amber-300'}`}
                >
                  {file ? (
                    <div className="text-center">
                      <FileText className="w-8 h-8 text-amber-500 mx-auto mb-2" />
                      <p className="text-amber-800 font-medium text-sm break-all px-4">{fileName}</p>
                      <p className="text-sm text-amber-600 mt-1">Click to change file</p>
                    </div>
                  ) : (
                    <div className="text-center">
                      <Upload className="w-8 h-8 text-amber-400 mx-auto mb-2" />
                      <p className="text-amber-800 font-medium">Upload PDF</p>
                      <p className="text-sm text-amber-600 mt-1">Click to browse files</p>
                    </div>
                  )}
                </label>
              </div>

              <div>
                <label className="block text-sm font-medium text-amber-800 dark:text-amber-200 mb-1">
                  Focus Area (Optional)
                </label>
                <input
                  type="text"
                  value={focusArea}
                  onChange={(e) => setFocusArea(e.target.value)}
                  placeholder="Specific topics to focus on from the PDF"
                  className="w-full px-4 py-2 border border-amber-200 dark:border-amber-900/50 rounded-lg
                           bg-white dark:bg-gray-900 text-amber-900 dark:text-amber-100"
                />
              </div>
            </>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-amber-800 dark:text-amber-200 mb-1">
                Number of Cards
              </label>
              <select
                value={numCards}
                onChange={(e) => setNumCards(Number(e.target.value))}
                className="w-full px-3 py-2 border border-amber-200 dark:border-amber-900/50 rounded-lg
                         bg-white dark:bg-gray-900 text-amber-900 dark:text-amber-100"
              >
                {[5, 10, 15, 20].map(num => (
                  <option key={num} value={num}>{num} cards</option>
                ))}
              </select>
            </div>

            <div>
                <label className="block text-sm font-medium text-amber-800 dark:text-amber-200 mb-1">
                    Category
                </label>
                {isCreatingCategory ? (
                    <div className="flex gap-2">
                    <input
                        type="text"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="Enter new category name"
                        className="flex-1 px-3 py-2 border border-amber-200 dark:border-amber-900/50 rounded-lg
                                bg-white dark:bg-gray-900 text-amber-900 dark:text-amber-100"
                        autoFocus
                    />
                    <Button
                        type="button"
                        onClick={async () => {
                        try {
                            if (!newCategory.trim()) return;
                            
                            // Call your handleAddCategory function
                            await handleAddCategory(newCategory.trim());
                            setSelectedCategory(newCategory.trim());
                            setNewCategory('');
                            setIsCreatingCategory(false);
                        } catch (error) {
                            toast.error(error.message);
                        }
                        }}
                        className="px-4 bg-amber-500 text-white"
                    >
                        Add
                    </Button>
                    <Button
                        type="button"
                        onClick={() => {
                        setIsCreatingCategory(false);
                        setNewCategory('');
                        }}
                        className="px-4 bg-gray-200 dark:bg-gray-700"
                    >
                        Cancel
                    </Button>
                    </div>
                ) : (
                    <div className="flex gap-2">
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="flex-1 px-3 py-2 border border-amber-200 dark:border-amber-900/50 rounded-lg
                                bg-white dark:bg-gray-900 text-amber-900 dark:text-amber-100"
                    >
                        {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                        ))}
                    </select>
                    <Button
                        type="button"
                        onClick={() => setIsCreatingCategory(true)}
                        className="px-4 bg-amber-50 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400
                                hover:bg-amber-100 dark:hover:bg-amber-900/40"
                    >
                        <Plus className="w-4 h-4 mr-1" />
                        New
                    </Button>
                    </div>
                )}
            </div>
            </div>

          {error && (
            <div className="p-3 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg flex items-center gap-2">
              <AlertCircle className="w-4 h-4 shrink-0" />
              <span>{error}</span>
            </div>
          )}

          <AICardCounter cardsGenerated={aiCardsGenerated} />

          <Button
            type="submit"
            disabled={loading || (mode === 'prompt' ? !topic.trim() : !file)}
            className="w-full py-3 bg-gradient-to-r from-amber-500 to-amber-600 
                     dark:from-amber-600 dark:to-amber-700 text-white
                     rounded-xl flex items-center justify-center gap-2"
          >
            {loading ? (
              <>
                <Loader className="w-5 h-5 animate-spin" />
                Generating Cards...
              </>
            ) : (
              <>
                {mode === 'prompt' ? <Wand2 className="w-5 h-5" /> : <FileText className="w-5 h-5" />}
                Generate Flashcards
              </>
            )}
          </Button>
        </form>

         {/* Example Prompts - Only show for prompt mode */}
         {mode === 'prompt' && (
          <div className="mt-8 border-t border-amber-100 dark:border-amber-900/50 pt-8">
            <h3 className="text-lg font-medium text-amber-800 dark:text-amber-200 mb-4">
              Example Prompts
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {EXAMPLE_PROMPTS.map(({ icon: Icon, title, examples }) => (
                <div key={title} className="bg-amber-50 dark:bg-amber-900/20 rounded-xl p-4">
                  <div className="flex items-center gap-2 mb-3">
                    <Icon className="w-5 h-5 text-amber-500 dark:text-amber-400" />
                    <h4 className="font-medium text-amber-800 dark:text-amber-200">{title}</h4>
                  </div>
                  <div className="space-y-2">
                    {examples.map((example, i) => (
                      <button
                      key={i}
                      onClick={() => {
                        setTopic(example);
                        if (formContainerRef.current) {
                          formContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                      }}
                      className="text-sm text-amber-600 dark:text-amber-400 hover:text-amber-800 dark:hover:text-amber-200 
                               block text-left hover:bg-amber-100/50 dark:hover:bg-amber-900/40 p-2 rounded-lg w-full 
                               transition-colors"
                    >
                      "{example}"
                    </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      </div>
    </div>
  );
}