// components/auth/SignUp.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { UserPlus, Loader } from 'lucide-react';
import { Button } from '../ui/Button';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
//const API_URL = process.env.REACT_APP_API_URL || 'https://api.recallpeak.com/api';

export const SignUp = ({ onSignUp, onSwitchToSignIn }) => {
 const [formData, setFormData] = useState({
   name: '',
   email: '',
   password: '',
   confirmPassword: ''
 });
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState('');

 const handleSubmit = async (e) => {
  e.preventDefault();
  if (formData.password !== formData.confirmPassword) {
    setError('Passwords do not match');
    return;
  }

  setLoading(true);
  setError('');

  try {
    const response = await fetch(`${API_URL}/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        password: formData.password
      })
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to sign up');
    }

    // Store token
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    
    // Call onSignUp with both user data and token
    onSignUp(data.user, data.token);
  } catch (err) {
    setError(err.message);
  } finally {
    setLoading(false);
  }
};

 return (
   <motion.div
     initial={{ opacity: 0, y: 20 }}
     animate={{ opacity: 1, y: 0 }}
     className="w-full max-w-md mx-auto bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6"
     >
     <h2 className="text-2xl font-bold text-amber-800 dark:text-amber-200 mb-6 text-center">
       Create your RecallPeak Account
     </h2>

     {error && (
       <div className="bg-red-100 dark:bg-red-900/20 border border-red-400 dark:border-red-800 text-red-700 dark:text-red-400 px-4 py-3 rounded mb-4"
>
         {error}
       </div>
     )}

     <form onSubmit={handleSubmit} className="space-y-4">
       <div>
         <label className="block text-sm font-medium text-amber-800 dark:text-amber-200 mb-1">
           Name
         </label>
         <input
           type="text"
           value={formData.name}
           onChange={(e) => setFormData({ ...formData, name: e.target.value })}
           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 dark:bg-gray-700 dark:border-gray-600 dark:text-amber-100 dark:placeholder-amber-400"
           required
         />
       </div>

       <div>
         <label className="block text-sm font-medium text-amber-800 dark:text-amber-200 mb-1">
           Email
         </label>
         <input
           type="email"
           value={formData.email}
           onChange={(e) => setFormData({ ...formData, email: e.target.value })}
           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 dark:bg-gray-700 dark:border-gray-600 dark:text-amber-100 dark:placeholder-amber-400"
           required
         />
       </div>

       <div>
         <label className="block text-sm font-medium text-amber-800 dark:text-amber-200 mb-1">
           Password
         </label>
         <input
           type="password"
           value={formData.password}
           onChange={(e) => setFormData({ ...formData, password: e.target.value })}
           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 dark:bg-gray-700 dark:border-gray-600 dark:text-amber-100 dark:placeholder-amber-400"
           required
         />
       </div>

       <div>
         <label className="block text-sm font-medium text-amber-800 dark:text-amber-200 mb-1">
           Confirm Password
         </label>
         <input
           type="password"
           value={formData.confirmPassword}
           onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 dark:bg-gray-700 dark:border-gray-600 dark:text-amber-100 dark:placeholder-amber-400"
           required
         />
       </div>

       <Button
         type="submit"
         className="w-full py-2 bg-amber-500 text-white flex items-center justify-center gap-2"
         disabled={loading}
       >
         {loading ? (
           <Loader className="w-4 h-4 animate-spin" />
         ) : (
           <UserPlus className="w-4 h-4" />
         )}
         Create Account
       </Button>
     </form>

     <div className="mt-4 text-center">
       <button
         onClick={onSwitchToSignIn}
         className="text-amber-600 dark:text-amber-400 hover:text-amber-700 dark:hover:text-amber-300"
         >
         Already have an account? Sign in
       </button>
     </div>
   </motion.div>
 );
};