import React, { useState, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Brain, 
  Plus,
  Sparkles, 
  ChevronRight, 
  Layout, 
  BookOpen,
  Target,
  TrendingUp,
  Zap,
  Lightbulb,
  Calendar,
  Grid,
  Book,
  GraduationCap,
  Globe,
  Code,
  Microscope,
  Music,
  Palette,
  History,
  FolderOpen,
  Shield,
  Library,
  BarChart,
  AlertCircle,
  X
} from 'lucide-react';
import { Button } from '../components/ui/Button';
import { LeitnerDashboard } from '../components/LeitnerDashboard';
import SpacedRepetitionStats from '../components/SpacedRepetitionStats';
import { getDueCount } from '../utils/dueDate';
import AIPDFGenerator from '../components/AIPDFGenerator';
import AIInputGenerator from '../components/AIInputGenerator';
import toast from 'react-hot-toast';
import AIGenerator from '../components/AIGenerator';
import { API_URL } from '../config/api';

const DEFAULT_CATEGORIES = [
  { name: 'General', icon: Book },
  { name: 'Science', icon: Microscope },
  { name: 'History', icon: History },
  { name: 'Language', icon: Globe },
  { name: 'Mathematics', icon: Brain },
  { name: 'Programming', icon: Code },
  { name: 'Arts', icon: Palette },
  { name: 'Music', icon: Music }
];

const StatCard = ({ icon: Icon, label, value, subtext, onClick }) => (
  <motion.div
    whileHover={{ y: -2, scale: 1.01 }}
    onClick={onClick}
    className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-amber-100 dark:border-gray-700 cursor-pointer
               hover:shadow-md transition-all group"
  >
    <div className="flex items-start justify-between">
      <div className="p-2 bg-amber-50 dark:bg-gray-700 rounded-lg">
        <Icon className="w-5 h-5 text-amber-600 dark:text-amber-400" />
      </div>
      <div className="px-2 py-1 bg-amber-50 dark:bg-gray-700 rounded-full text-xs font-medium text-amber-600 dark:text-amber-400">
        View Details
      </div>
    </div>
    <div className="mt-3">
      <h3 className="text-2xl font-bold text-amber-900 dark:text-amber-200">{value}</h3>
      <p className="text-amber-600 dark:text-amber-400 text-sm mt-1">{label}</p>
      {subtext && (
        <p className="text-amber-500 dark:text-amber-500 text-xs mt-2">{subtext}</p>
      )}
    </div>
  </motion.div>
);

const CreateCategoryModal = ({ onClose, handleAddCategory }) => {
  const [newCategory, setNewCategory] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newCategory.trim()) {
      setError('Category name cannot be empty');
      return;
    }

    setLoading(true);
    try {
      await handleAddCategory(newCategory.trim());
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center p-4"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-md w-full"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-amber-800 dark:text-amber-200 flex items-center gap-2">
            <FolderOpen className="w-5 h-5 text-amber-600 dark:text-amber-400" />
            Create New Category
          </h2>
          <Button
            onClick={onClose}
            className="p-2 hover:bg-amber-50 dark:hover:bg-gray-700 rounded-lg"
          >
            <X className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          </Button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg flex items-center gap-2">
            <AlertCircle className="w-4 h-4" />
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-amber-800 dark:text-amber-200 mb-1">
              Category Name
            </label>
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              className="w-full px-4 py-2 border border-amber-200 dark:border-amber-900/50 rounded-lg
                       bg-white dark:bg-gray-900 text-amber-900 dark:text-amber-100
                       focus:outline-none focus:ring-2 focus:ring-amber-500"
              placeholder="Enter category name"
              autoFocus
            />
          </div>

          <div className="flex justify-end gap-3">
            <Button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-amber-600 dark:text-amber-400 hover:bg-amber-50 dark:hover:bg-gray-700"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-amber-500 dark:bg-amber-600 text-white"
            >
              {loading ? 'Creating...' : 'Create Category'}
            </Button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

const CategoryCard = ({ name, icon: Icon, count, isSelected, onClick, onStudy }) => {
  const handleStudyClick = (e) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    e.stopPropagation();
    if (typeof onStudy === 'function') {
      onStudy();
    } else {
      console.warn('onStudy prop is not a function');
    }
  };

  const handleCardClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    } else {
      console.warn('onClick prop is not a function');
    }
  };

  return (
    <motion.div
      whileHover={{ y: -2, scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={handleCardClick}
      className={`relative p-6 rounded-xl cursor-pointer transition-all
                  ${isSelected 
                    ? 'bg-amber-50 dark:bg-gray-700 border-2 border-amber-400 shadow-md' 
                    : 'bg-white dark:bg-gray-800 border border-amber-100 dark:border-gray-700 hover:shadow-md'}`}
    >
      <div className="flex flex-col items-center text-center gap-4">
        <div className={`p-4 rounded-xl ${isSelected ? 'bg-amber-100 dark:bg-gray-600' : 'bg-amber-50 dark:bg-gray-700'}`}>
          <Icon className={`w-8 h-8 ${isSelected ? 'text-amber-600 dark:text-amber-400' : 'text-amber-500 dark:text-amber-300'}`} />
        </div>
        <div>
          <h3 className={`text-lg font-medium ${isSelected ? 'text-amber-900 dark:text-amber-200' : 'text-amber-800 dark:text-amber-400'}`}>
            {name}
          </h3>
          <p className={`text-sm ${isSelected ? 'text-amber-700 dark:text-amber-500' : 'text-amber-600 dark:text-amber-400'}`}>
            {count} cards
          </p>
        </div>
        {count > 0 && (
          <Button
            onClick={handleStudyClick}

            className={`w-full py-2 rounded-lg transition-all
                       ${isSelected 
                         ? 'bg-amber-400 text-white hover:bg-amber-500' 
                         : 'bg-amber-50 dark:bg-gray-700 text-amber-600 dark:text-amber-400 hover:bg-amber-100 dark:hover:bg-gray-600'}`}
          >
            Study Now
          </Button>
        )}
      </div>
    </motion.div>
  );
};


const DashboardView = ({ 
  cards, 
  setCards, 
  setView, 
  selectedCategory, 
  setSelectedCategory,
  isAdmin,
  setStudyMode  
}) => {
  const [showAIGenerator, setShowAIGenerator] = useState(false);
  const [showCategoryManager, setShowCategoryManager] = useState(false);
  const [aiCardsGenerated, setAiCardsGenerated] = useState(0);
  const [categories, setCategories] = useState(['General']);
  const [showCreateCategory, setShowCreateCategory] = useState(false);

  // At the top of DashboardView, add:
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_URL}/categories`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }

        const data = await response.json();
        
        // Ensure General category is always present
        const uniqueCategories = new Set(['General']);
        data.forEach(category => {
          if (typeof category === 'object') {
            uniqueCategories.add(category.name);
          } else {
            uniqueCategories.add(category);
          }
        });

        setCategories(Array.from(uniqueCategories));
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Failed to load categories');
      }
    };

    fetchCategories();
  }, []); // Empty dependency array for initial load

  // Get actual categories from cards if not provided in props
  const availableCategories = React.useMemo(() => {
    const categorySet = new Set(['General']);
    cards.forEach(card => {
      if (card.category) {
        categorySet.add(card.category);
      }
    });
    return Array.from(categorySet);
  }, [cards]);

  const handleCategoryStudy = (category) => {
    setSelectedCategory(category);
    setStudyMode('category');  // Explicitly set to 'category' mode
    setView('study');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDueStudy = () => {
    setStudyMode('due');
    setView('study');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getCategoryCount = (categoryName) => {
    if (categoryName === 'All') return cards.length;
    return cards.filter(card => card.category === categoryName).length;
  };

  // Get category counts
  const getCategoryStats = useMemo(() => {
    const stats = new Map();
    // Initialize all categories with 0 count
    categories.forEach(category => {
      stats.set(category, 0);
    });
    // Count cards for each category
    cards.forEach(card => {
      if (card.category) {
        stats.set(card.category, (stats.get(card.category) || 0) + 1);
      }
    });
    return stats;
  }, [cards, categories]);

  // Get icon for category (you can add more icons as needed)
  const getCategoryIcon = (category) => {
    switch (category) {
      case 'General':
        return Book;
      case 'Science':
        return Microscope;
      case 'History':
        return History;
      case 'Language':
        return Globe;
      case 'Mathematics':
        return Brain;
      case 'Programming':
        return Code;
      default:
        return FolderOpen; // Default icon for custom categories
    }
  };

  const calculateAccuracyRate = () => {
    const cardsWithHistory = cards.filter(card => card.box > 1);
    if (cardsWithHistory.length === 0) return 0;
    
    const accuracy = (cardsWithHistory.length / cards.length) * 100;
    return Math.round(accuracy);
  };

  const fetchAICardCount = async () => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const aiCards = cards.filter(card => 
        card.source === 'ai' && 
        new Date(card.created_at) >= today
      );
      
      setAiCardsGenerated(aiCards.length);
    } catch (error) {
      console.error('Error fetching AI card count:', error);
    }
  };
  
  // Call it when component mounts and when cards change
  useEffect(() => {
    fetchAICardCount();
  }, [cards]);

  const handleAddCategory = async (name) => {
    try {
      const response = await fetch(`${API_URL}/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify({ name })
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to add category');
      }
  
      const data = await response.json();
      setCategories(prev => [...prev, name]);
      return data;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 space-y-8">
  {/* Welcome Section */}
    <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
    <div>
        <h1 className="text-3xl font-bold text-amber-800 dark:text-amber-200">
        Welcome back!
        </h1>
        <p className="text-amber-600 dark:text-amber-400 mt-2">
        Track your progress and keep learning
        </p>
    </div>
    <div className="flex flex-col sm:flex-row gap-3 w-full md:w-auto">
        {/* Admin Button */}
        {isAdmin && (
        <Button
            onClick={() => {setView('admin'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
            className="px-4 py-3 bg-amber-50 dark:bg-amber-900/30 text-amber-600 dark:text-amber-400 
                    hover:bg-amber-100 dark:hover:bg-amber-900/50
                    rounded-xl shadow-sm hover:shadow-md transition-all
                    flex items-center justify-center gap-2 w-full sm:w-auto"
        >
            <Shield className="w-5 h-5" />
            <span>Admin Panel</span>
        </Button>
        )}
        {/* AI Generate Button */}
        <Button
        onClick={() => setShowAIGenerator(true)}
        className="px-4 py-3 bg-amber-50 dark:bg-amber-900/30 text-amber-600 dark:text-amber-400 
                hover:bg-amber-100 dark:hover:bg-amber-900/50
                rounded-xl shadow-sm hover:shadow-md transition-all
                flex items-center justify-center gap-2 w-full sm:w-auto"
        >
        <Sparkles className="w-5 h-5" />
        <span>AI Generate</span>
        </Button>
        {/* Create Category Button */}
        <Button
            onClick={() => setShowCreateCategory(true)}
            className="px-4 py-3 bg-amber-50 dark:bg-amber-900/30 text-amber-600 dark:text-amber-400 
                     hover:bg-amber-100 dark:hover:bg-amber-900/50 rounded-xl shadow-sm hover:shadow-md 
                     transition-all flex items-center justify-center gap-2 w-full sm:w-auto"
          >
            <FolderOpen className="w-5 h-5" />
            <span>Create Category</span>
          </Button>
        {/* Create Card Button */}
        <Button
        onClick={() => {setView('add'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
        className="px-4 py-3 bg-gradient-to-r from-amber-500 to-amber-600 
                text-white rounded-xl shadow-sm hover:shadow-md transition-all
                flex items-center justify-center gap-2 w-full sm:w-auto"
        >
        <Plus className="w-5 h-5" />
        <span>Create Card</span>
        </Button>
    </div>
    </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <StatCard
          icon={BookOpen}
          label="Total Cards"
          value={cards.length}
          subtext="Across all categories"
          onClick={() => {setView('progress'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
        />
        <StatCard
          icon={Calendar}
          label="Due Today"
          value={getDueCount(cards)}
          subtext="Cards to review"
          onClick={() => {setView('study'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
        />
        <StatCard
          icon={Target}
          label="Mastered"
          value={cards.filter(card => card.box === 5).length}
          subtext={`${Math.round((cards.filter(card => card.box === 5).length / (cards.length || 1)) * 100)}% completion`}
          onClick={() => {setView('progress'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
        />
        <StatCard
          icon={BarChart}
          label="Accuracy Rate"
          value={`${calculateAccuracyRate()}%`}  // Calculate based on card review history
          subtext="Overall performance"
          onClick={() => {setView('progress'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
        />
      </div>

      
    {/* Learning Progress */}
    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 border border-amber-100 dark:border-gray-700">
        <h2 className="text-xl font-bold text-amber-800 dark:text-amber-200 mb-4">
        Leitner Progress
        </h2>
        <LeitnerDashboard
        cards={cards}
        setStudyMode={setStudyMode} // Pass this
        setView={setView}
        onStartStudy={handleDueStudy}
        /* onStartStudy={() => {setStudyMode('due'); setView('study'); window.scrollTo({ top: 0, behavior: 'smooth' });}} */
        />
    </div>

      {/* Categories Grid */}
      {/* Categories Grid */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 border border-amber-100 dark:border-gray-700">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-6">
            <div>
            <h2 className="text-xl font-bold text-amber-800 dark:text-amber-200 flex items-center gap-2">
                <Grid className="w-5 h-5" />
                Categories
            </h2>
            <p className="text-amber-600 dark:text-amber-400 text-sm mt-1">
                {categories.length} categories, {cards.length} total cards
            </p>
            </div>
            {/* Management Buttons */}
            <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
            <Button
                onClick={() => {setView('manage-cards'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                className="px-4 py-2 bg-amber-50 dark:bg-amber-900/30 text-amber-600 dark:text-amber-400 
                        hover:bg-amber-100 dark:hover:bg-amber-900/50 rounded-lg
                        flex items-center justify-center gap-2 w-full sm:w-auto"
            >
                <Library className="w-4 h-4" />
                Manage Cards
            </Button>
            <Button
                onClick={() => {setView('manage-categories'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
                className="px-4 py-2 bg-amber-50 dark:bg-amber-900/30 text-amber-600 dark:text-amber-400 
                        hover:bg-amber-100 dark:hover:bg-amber-900/50 rounded-lg
                        flex items-center justify-center gap-2 w-full sm:w-auto"
            >
                <FolderOpen className="w-4 h-4" />
                Manage Categories
            </Button>
            </div>
        </div>

    {/* Categories grid */}
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
    {categories.map((category) => {
        const Icon = getCategoryIcon(category);
        const count = getCategoryStats.get(category) || 0;

        return (
        <CategoryCard
            key={category}
            name={category}
            icon={Icon}
            count={count}
            isSelected={selectedCategory === category}
            onClick={() => setSelectedCategory(category)}
            onStudy={() => handleCategoryStudy(category)} 
        />
        );
    })}
    </div>
    </div>

    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 border border-amber-100 dark:border-gray-700">
        <h2 className="text-xl font-bold text-amber-800 dark:text-amber-200 mb-4">
        Study Statistics
        </h2>
        <SpacedRepetitionStats 
        cards={cards} 
        onStartStudy={() => {setView('study'); window.scrollTo({ top: 0, behavior: 'smooth' });}}
        />
    </div>

    {/* Create Category Modal */}
    <AnimatePresence>
        {showCreateCategory && (
          <CreateCategoryModal
            onClose={() => setShowCreateCategory(false)}
            handleAddCategory={handleAddCategory}
          />
        )}
      </AnimatePresence>

    {/* AI Generator Modal */}
    <AnimatePresence>
    {showAIGenerator && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 flex items-center justify-center p-4"
        onClick={(e) => e.target === e.currentTarget && setShowAIGenerator(false)}
      >
        <motion.div 
          initial={{ scale: 0.95 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.95 }}
          className="w-full max-w-3xl max-h-[90vh] overflow-y-auto"
        >
          <AIGenerator 
            categories={categories}
            aiCardsGenerated={aiCardsGenerated}
            handleAddCategory={handleAddCategory}
            onSuccess={(data) => {
              setCards(prev => [...prev, ...data.cards]);
              // Update the AI card count based on the response
              setAiCardsGenerated(data.aiGeneratedToday);
              
              if (data.isNewCategory && !categories.includes(data.category)) {
                setCategories(prev => [...prev, data.category]);
              }
              
              toast.success(
                `Created ${data.cards.length} cards in ${data.category}. ${data.remainingToday} generations remaining today.`
              );
              
              setShowAIGenerator(false);
              setSelectedCategory(data.category);
              setView('study');
            }}
            onClose={() => setShowAIGenerator(false)}
          />
        </motion.div>
      </motion.div>
    )}
</AnimatePresence>
    </div>
  );
};

export default DashboardView;